import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  BundleDataTableColumn,
  BundleDataTableData,
  BundleDataTableRow,
  medianColumnHeader,
  p95ColumnHeader,
} from '../../global/components/bundle-data-table/bundle-data.types';
import { BackendService } from '@squidcloud/console-web/app/backend/backend.service';
import { formatAsTimePeriod } from '@squidcloud/console-web/app/global/utils/formatting-utils';
import { BackendMetricPeriodType } from '@squidcloud/console-common/types/metrics.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  templateUrl: './security-rules.component.html',
  styleUrls: ['./security-rules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecurityRulesComponent {
  protected readonly bundleDataTableData$: Observable<BundleDataTableData | undefined>;
  protected readonly periodType$ = new BehaviorSubject<BackendMetricPeriodType>('last-hour');

  constructor(private readonly backendService: BackendService) {
    this.backendService.reportBackendMetadataAvailable(false);

    this.bundleDataTableData$ = this.backendService
      .observeMetricByPeriodType(this.periodType$, takeUntilDestroyed())
      .pipe(
        map(metricsResponse => {
          const metrics = metricsResponse.functionsExecutionCounts?.['security'];
          if (!metrics) return undefined;
          const rows: Array<BundleDataTableRow> = metrics.functions
            // Sort by integration (db) first and by collection name next and by action
            // next.
            .sort((m1, m2) => {
              const extras1 = m1.functionData.extras;
              const extras2 = m2.functionData.extras;
              const integrationId1 = extras1['integrationId'] as string;
              const integrationId2 = extras2['integrationId'] as string;
              let result = integrationId1.localeCompare(integrationId2);
              if (result === 0) {
                const collectionName1 = extras1['collectionName'] as string;
                const collectionName2 = extras2['collectionName'] as string;
                result = collectionName1.localeCompare(collectionName2);
                if (result === 0) {
                  const action1 = extras1['action'] as string;
                  const action2 = extras2['action'] as string;
                  result = action1.localeCompare(action2);
                }
              }
              return result;
            })
            .map(metric => {
              const functionData = metric.functionData;
              const counts = metric.counts;
              const extras = functionData.extras;
              const functionName = functionData.serviceFunctionName as string;
              const integrationId = extras['integrationId'] as string;
              const collectionName = extras['collectionName'] as string;
              const action = extras['action'] as string;
              const columns: Array<BundleDataTableColumn> = [
                { value: integrationId },
                { value: collectionName },
                { value: action },
                {
                  value: `${counts.success}`,
                  sortValue: counts.success,
                },
                {
                  value: `${counts.errors}`,
                  sortValue: counts.errors,
                  class: counts.errors > 0 ? 'error' : 'muted',
                },
                {
                  value: formatAsTimePeriod(counts.successMedian),
                  sortValue: counts.successMedian,
                },
                {
                  value: formatAsTimePeriod(counts.successP95),
                  sortValue: counts.successP95,
                },
                {
                  value: functionName,
                  tooltip: functionName,
                },
              ];
              return { columns };
            });

          this.backendService.reportBackendMetadataAvailable(!!rows.length);
          if (!rows.length) return undefined;
          return {
            headerRow: [
              'Integration',
              'Collection',
              'Action',
              { name: 'Executions', align: 'right' },
              { name: 'Errors', align: 'right' },
              medianColumnHeader,
              p95ColumnHeader,
              'Function',
            ],
            rows,
            metrics: metrics.charts,
            showPeriodSelector: true,
          };
        }),
      );
  }
}
