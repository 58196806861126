export enum IntegrationType {
  'built_in_db' = 'built_in_db',
  'mongo' = 'mongo',
  'mysql' = 'mysql',
  'clickhouse' = 'clickhouse',
  'mssql' = 'mssql',
  'postgres' = 'postgres',
  'cockroach' = 'cockroach',
  'api' = 'api',
  'graphql' = 'graphql',
  'snowflake' = 'snowflake',
  'datadog' = 'datadog',
  'newrelic' = 'newrelic',
  'auth0' = 'auth0',
  'jwt_rsa' = 'jwt_rsa',
  'jwt_hmac' = 'jwt_hmac',
  'ai_chatbot' = 'ai_chatbot',
  'cognito' = 'cognito',
  'okta' = 'okta',
  'descope' = 'descope',
  'firebase_auth' = 'firebase_auth',
  'kafka' = 'kafka',
  'confluent' = 'confluent',
  'built_in_queue' = 'built_in_queue',
  's3' = 's3',
  'gcs' = 'gcs',
  'built_in_s3' = 'built_in_s3',
  'built_in_gcs' = 'built_in_gcs',
  'google_drive' = 'google_drive',
  'ai_agents' = 'ai_agents',
  // Coming Soon / Request
  'algolia' = 'algolia',
  'elastic_observability' = 'elastic_observability',
  'elastic_search' = 'elastic_search',
  'elastic_enterprise_search' = 'elastic_enterprise_search',
  'sentry' = 'sentry',
  'sap_hana' = 'sap_hana',
  'salesforce_crm' = 'salesforce_crm',
  'documentdb' = 'documentdb',
  'dynamodb' = 'dynamodb',
  'cassandra' = 'cassandra',
  'alloydb' = 'alloydb',
  'spanner' = 'spanner',
  'db2' = 'db2',
  'mariadb' = 'mariadb',
  'oracledb' = 'oracledb',
  'redis' = 'redis',
  'xata' = 'xata',
  'azure_sql' = 'azure_sql',
  'azure_postgresql' = 'azure_postgresql',
  'azure_cosmosdb' = 'azure_cosmosdb',
  'firestore' = 'firestore',
  'bigquery' = 'bigquery',
  'cloudsql' = 'cloudsql',
  'slack' = 'slack',
  'hubspot' = 'hubspot',
  'monday' = 'monday',
  'google_docs' = 'google_docs',
  'onedrive' = 'onedrive',
  'zendesk' = 'zendesk',
  'pinecone' = 'pinecone',
  'active_directory' = 'active_directory',
  'jira' = 'jira',
  'confluence' = 'confluence',
  'servicenow' = 'servicenow',
  // Link to docs
  'linear' = 'linear',
}

export enum IntegrationSchemaType {
  'data' = 'data',
  'api' = 'api',
  'graphql' = 'graphql',
}
