import { UserFeatureId } from '@squidcloud/internal-common/features/user-features';

/** Unique user id. Same as Auth0 user id. */
export type CpUserId = string;

/** Collection name where CpUser record is stored in the local db (mongo). */
export const USER_COLLECTION = 'user';

export interface CpUserUiPreferences {
  dismissedPaymentDetailsBanner?: boolean;
  sideBarCollapsed?: boolean;
}

export const USER_DETAILS_ROLES = [
  'fullstack-developer',
  'backend-developer',
  'frontend-developer',
  'product-manager',
  'engineering-manager',
  'sales-engineer',
] as const;

export type UserDetailsRole = (typeof USER_DETAILS_ROLES)[number];

export interface CpUserDetails {
  role?: UserDetailsRole | string;
}

/** Console user model. */
export interface CpUser {
  /** Unique user id. Provided by Auth0. */
  id: CpUserId;
  /** Unique user email. Stored in DB in a lower-case (normalized) form. */
  email: string;
  /** User display name in any form. The preferred form is a 'FirstName LastName'. Example: Sam Loudermilk.*/
  name: string;
  /** User avatar URL. An image resource. Example: https://www.gravatar.com/avatar/00000000000000000000000000000000?d=robohash. */
  picture?: string;
  /**
   * The date the user was registered in Squid Console.
   * If not defined the user was registered before May 2024.
   */
  creationDate?: Date;
  /** User UI settings. */
  uiPreferences?: CpUserUiPreferences;
  squidDeveloperId: string;
  /** List of features enabled for the user. */
  features?: Array<UserFeatureId>;
  /** List of attributes providing more details about the user */
  details?: CpUserDetails;
  /**
   * When present contains cached auth0 specific information about the user.
   * Automatically created/updated on every user sign-in to the console.
   */
  auth0Info?: Auth0UserInfo;
}

/**  A request model to create a new Console user. */
export interface CreateUserRequest {
  /** Unique user id. Same as in Auth0. */
  id: CpUserId;
  /** User display name. Derived from Auth0 attributes or email. It can be changed later. */
  name: string;
}

export interface CreateUserResponse {
  /** Created user id. If the new account is linked the 'id' is an old (master) account id. */
  id: CpUserId;
}

/** Request the backend to send a new email with an email verification link for a non-verified Auth0 user account. */
export interface SendEmailVerificationLinkRequest {
  /** Auth0 user 'sub'. */
  auth0Sub: string;
}

export const AUTH0_IDENTITY_PROVIDER_TYPES = ['auth0', 'google-oauth2', 'github'] as const;
export type Auth0IdentityProviderType = (typeof AUTH0_IDENTITY_PROVIDER_TYPES)[number];

// noinspection JSUnusedGlobalSymbols
export function isAuth0IdentityProviderType(v: unknown): v is Auth0IdentityProviderType {
  return AUTH0_IDENTITY_PROVIDER_TYPES.includes(v as Auth0IdentityProviderType);
}

/**
 * Represents the Auth0 user details stored in our database.
 *
 * We store these details locally for the following reasons:
 * 1. The Auth0 API is rate-limited, making frequent access impractical.
 * 2. We rely on reactive UI updates triggered by changes to the data model,
 *    which isn't feasible if the data is only stored in Auth0.
 */
export interface Auth0UserInfo {
  /** Time the identities details were received from Auth0. */
  identitiesDate: Date;
  /**
   * Contains information about all user accounts (Auth0 identities).
   *
   * This array is updated each time the user signs in to the console app.
   * It should never be empty and must always include at least the current identity.
   */
  identities: Array<Auth0UserIdentity>;
}

export interface Auth0UserIdentity {
  provider: Auth0IdentityProviderType;
  /**
   * 'Provider' provided user id.
   * Important: it different from 'sub' (aka CpUserId) because 'sub' includes provider, (example: `auth0|<userId>`)
   * while here 'userId' has no provider prefix.
   */
  userId: string;
}

/** Access token model returned by Auth0 for Console app. */
export interface Auth0ConsoleJwtPayload {
  email: string;
  picture: string;
  identities: Array<{ provider: Auth0IdentityProviderType; user_id: CpUserId }>;
  iss: string;
  sub: string;
  aud: Array<string>;
  /** "Issued at" - time the access token was issued by Auth0. */
  iat: number;
  exp: number;
  scope: string;
  azp: string;
}
