import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ApplicationService } from '../application.service';
import { LogEntry, LogLevel } from './logs.types';
import { Pagination, Squid } from '@squidcloud/client';
import { BehaviorSubject, debounceTime, filter, firstValueFrom, interval, of, ReplaySubject, switchMap } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LogEntryDialogComponent } from './log-entry-dialog/log-entry-dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LogEntryInDb } from '@squidcloud/console-common/types/application.types';
import { AccountService } from '../../account/account.service';
import { parseAppId } from '@squidcloud/internal-common/types/communication.types';
import { MatSelectChange } from '@angular/material/select';
import { MILLIS_PER_MINUTE } from '@squidcloud/internal-common/types/time-units';

interface LogsTimestampOption {
  label: string;
  value: number | null;
}

interface LogLevelOption {
  label: string;
  value: LogLevel | undefined;
}

@Component({
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsComponent {
  applicationObs = this.applicationService.observeCurrentApplication();
  readonly allTimestampOptions: Array<LogsTimestampOption> = [
    { label: 'All time', value: 0 },
    { label: 'Last 5 minutes', value: 5 },
    { label: 'Last 15 minutes', value: 15 },
    { label: 'Last 30 minutes', value: 30 },
    { label: 'Last 1 hour', value: 60 },
    { label: 'Last 3 hours', value: 180 },
    { label: 'Last day', value: 1440 },
  ];

  readonly allLogLevelOptions: Array<LogLevelOption> = [
    { label: 'All', value: undefined },
    { label: 'Trace', value: 'trace' },
    { label: 'Debug', value: 'debug' },
    { label: 'Info', value: 'info' },
    { label: 'Warning', value: 'warn' },
    { label: 'Error', value: 'error' },
  ];
  /** The period in minutes from Date.now() to display log messages. */
  logDisplayPeriodInMinutes: number | null | undefined;
  currentLogLevel: string | undefined;

  searchKeywordsSubject = new BehaviorSubject<string>('');
  receivedDataFromServer = false;
  hasNextPage = false;
  hasPreviousPage = false;
  serverRequestInProgress = false;

  appIdOptions: { label: string; value: string }[] | undefined = undefined;
  selectedAppId: string | undefined = undefined;

  readonly logEntriesObs = new ReplaySubject<Array<LogEntry>>(1);
  private readonly paginationSubject = new BehaviorSubject<Pagination<LogEntryInDb> | undefined>(undefined);
  readonly refreshInProgressSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly squid: Squid,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly accountService: AccountService,
  ) {
    this.searchKeywordsSubject.pipe(debounceTime(300), takeUntilDestroyed()).subscribe(async () => {
      await this.waitForRefreshToFinish();
      void this.executeQuery();
    });

    // Reset pagination state on application change.
    applicationService.currentApplication$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.paginationSubject.next(undefined);
      this.setAppIdOptions();
      void this.executeQuery();
    });

    this.setAppIdOptions();

    this.paginationSubject
      .pipe(
        switchMap(pagination => pagination?.observeState() || of(undefined)),
        takeUntilDestroyed(),
      )
      .subscribe(paginationState => {
        if (!paginationState) {
          this.hasNextPage = false;
          this.hasPreviousPage = false;
          this.logEntriesObs.next([]);
          this.cdr.markForCheck();
          return;
        }
        this.hasNextPage = paginationState.hasNext;
        this.hasPreviousPage = paginationState.hasPrev;
        this.logEntriesObs.next(
          paginationState.data.map(logEntryInDb => {
            let parsedMessage: string[] = [];
            try {
              const parseResult = JSON.parse(logEntryInDb.message) as unknown;
              if (Array.isArray(parseResult)) {
                parsedMessage = parseResult as string[];
              } else if (typeof parseResult === 'string') {
                parsedMessage.push(parseResult);
              } else {
                console.warn('Unexpected logEntryInDb.message type', typeof logEntryInDb.message);
                parsedMessage.push(logEntryInDb.message);
              }
            } catch (e) {
              console.error('Error', e);
              parsedMessage.push(logEntryInDb.message);
            }
            let tags: Record<string, string> = {};
            try {
              tags = (logEntryInDb.tags || {}) as Record<string, string>;
            } catch (e) {
              console.log('Error parsing logs tags', e);
            }

            const messages = parsedMessage.map(message => {
              if (typeof message === 'object') {
                return JSON.stringify(message, null, 2);
              }
              return message;
            });

            return {
              id: logEntryInDb.id,
              timestamp: logEntryInDb.timestamp,
              message: messages.join(' '),
              level: logEntryInDb.level,
              service: 'squid',
              tags: tags || {},
            };
          }),
        );
        this.serverRequestInProgress = false;
        this.receivedDataFromServer = true;
        this.cdr.markForCheck();
      });

    interval(10_000)
      .pipe(takeUntilDestroyed())
      .subscribe(async () => {
        if (!this.paginationSubject.value || this.refreshInProgressSubject.value || this.serverRequestInProgress) {
          return;
        }
        try {
          this.refreshInProgressSubject.next(true);
          await this.paginationSubject.value.refreshPage();
        } finally {
          this.refreshInProgressSubject.next(false);
        }
      });
  }

  onSourceSelectionChange(event: MatSelectChange): void {
    this.selectedAppId = event.value;
    void this.executeQuery();
  }

  private setAppIdOptions(): void {
    const application = this.applicationService.getCurrentApplication();
    if (application && parseAppId(application.appId).environmentId === 'dev') {
      const appId = application.appId;
      this.appIdOptions = [
        { label: 'dev', value: appId },
        { label: 'local', value: appId + '-' + this.accountService.getUserOrFail().squidDeveloperId },
      ];
      this.selectedAppId = appId;
    } else {
      this.appIdOptions = undefined;
      this.selectedAppId = undefined;
    }
  }

  private async executeQuery(): Promise<void> {
    const application = this.applicationService.getCurrentApplication();
    if (!application) {
      return;
    }
    const { appId } = application;
    this.serverRequestInProgress = true;
    this.cdr.markForCheck();
    const queryBuilder = this.squid
      .collection<LogEntryInDb>('logs', 'clickhouse')
      .query()
      .eq('appId', this.selectedAppId || appId)
      .eq('isExposedToUser', 1)
      .sortBy('timestamp', false);
    if (this.logDisplayPeriodInMinutes) {
      const fromDate = new Date(Date.now() - this.logDisplayPeriodInMinutes * MILLIS_PER_MINUTE);
      queryBuilder.gte('timestamp', fromDate);
    }

    if (this.currentLogLevel !== undefined) {
      queryBuilder.eq('level', this.currentLogLevel);
    }

    if (this.searchKeywordsSubject.value !== '') {
      queryBuilder.like('message', `%${this.searchKeywordsSubject.value}%`, false);
    }

    const pagination = queryBuilder.dereference().paginate({ pageSize: 100, subscribe: false });
    this.paginationSubject.next(pagination);
  }

  showLogEntryDialog(logEntry: LogEntry): void {
    const config: MatDialogConfig = {
      maxWidth: '800px',
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data: { logEntry },
    };

    this.dialog.open(LogEntryDialogComponent, config);
  }

  handleLogDisplayPeriodInMinutesChanged(value: number): void {
    this.logDisplayPeriodInMinutes = value;
    void this.executeQuery();
  }

  handleLogLevelChanged(value: string): void {
    this.currentLogLevel = value;
    void this.executeQuery();
  }

  onSearchKeywordChanged(event: Event): void {
    const input = event.target as HTMLInputElement;
    const keyword = input.value;
    this.searchKeywordsSubject.next(keyword);
  }

  async previousPage(): Promise<void> {
    await this.waitForRefreshToFinish();
    this.serverRequestInProgress = true;
    this.paginationSubject.value?.prev();
  }

  async nextPage(): Promise<void> {
    await this.waitForRefreshToFinish();
    this.serverRequestInProgress = true;
    this.paginationSubject.value?.next();
  }

  private async waitForRefreshToFinish(): Promise<void> {
    await firstValueFrom(this.refreshInProgressSubject.pipe(filter(value => !value)));
  }
}
