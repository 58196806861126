<div class="page_header" [ngClass]="{ bottom_border: withBottomBorder }">
  <div class="left">
    <div class="title_wrapper">
      <span class="title_prefix">{{title}}</span>
      <ng-container *ngIf="subtitle">
        <span class="separator">//</span>
        <span class="title">{{ subtitle }}</span>
      </ng-container>
    </div>
    <div class="description" *ngIf="description">{{ description }}</div>
    <div class="image_and_secondary" *ngIf="image || secondaryTitle">
      <img class="image" *ngIf="image" [src]="image" />
      <div class="secondary_text_wrapper" *ngIf="secondaryTitle">
        <div class="secondary_title" [innerHTML]="secondaryTitle"></div>
        <div class="secondary_description" [innerHTML]="secondaryDescription"></div>
      </div>
    </div>
  </div>
  <div class="right">
    <ng-content></ng-content>
  </div>
</div>
