import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  BundleDataTableColumn,
  BundleDataTableData,
  BundleDataTableRow,
  medianColumnHeader,
  p95ColumnHeader,
} from '../../global/components/bundle-data-table/bundle-data.types';
import { BackendService } from '@squidcloud/console-web/app/backend/backend.service';
import {
  BackendMetricPeriodType,
  getServiceNameFromFunctionName,
} from '@squidcloud/console-common/types/metrics.types';
import { formatAsTimePeriod } from '@squidcloud/console-web/app/global/utils/formatting-utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  templateUrl: './schedulers.component.html',
  styleUrls: ['./schedulers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchedulersComponent {
  protected readonly bundleDataTableData$: Observable<BundleDataTableData | undefined>;
  protected readonly periodType$ = new BehaviorSubject<BackendMetricPeriodType>('last-hour');

  constructor(private readonly backendService: BackendService) {
    this.bundleDataTableData$ = this.backendService
      .observeMetricByPeriodType(this.periodType$, takeUntilDestroyed())
      .pipe(
        map(metricsResponse => {
          const metrics = metricsResponse.functionsExecutionCounts?.['scheduler'];
          if (!metrics) return undefined;
          const rows: Array<BundleDataTableRow> = metrics.functions.map(metric => {
            const functionData = metric.functionData;
            const counts = metric.counts;
            const extras = functionData.extras;
            const schedulerId = extras['id'] as string;
            const functionName = functionData.serviceFunctionName as string;
            const cronExpression = extras['cronExpression'] as string;
            const columns: Array<BundleDataTableColumn> = [
              { value: schedulerId, tooltip: schedulerId },
              { value: `${counts.success}`, sortValue: counts.success },
              {
                value: `${counts.errors}`,
                sortValue: counts.errors,
                class: counts.errors > 0 ? 'error' : 'muted',
              },
              {
                value: formatAsTimePeriod(counts.successP95),
                sortValue: counts.successP95,
              },
              {
                value: formatAsTimePeriod(counts.successMedian),
                sortValue: counts.successMedian,
              },
              { value: cronExpression },
              { value: getServiceNameFromFunctionName(functionName) },
            ];
            return { columns };
          });

          this.backendService.reportBackendMetadataAvailable(!!rows.length);
          if (!rows.length) return undefined;

          return {
            headerRow: [
              'ID',
              { name: 'Executions', align: 'right' },
              { name: 'Errors', align: 'right' },
              medianColumnHeader,
              p95ColumnHeader,
              'Cron expression',
              'Service',
            ],
            rows,
            metrics: metrics.charts,
            showPeriodSelector: true,
          };
        }),
      );

    this.backendService.reportBackendMetadataAvailable(false);
  }
}
