import {
  AiAskResponse,
  AiAskWithVoiceResponse,
  AiChatbotChatOptions,
  AiChatbotContext,
  AiChatModelName,
  AiSearchOptions,
  AiSearchRequest,
  AiSearchResponse,
  AiTranscribeAndAskResponse,
  AiTranscribeAndAskWithVoiceResponse,
  AiTranscribeAndChatResponse,
  ClientRequestId,
  IntegrationId,
  validateAiContextMetadata,
  validateAiContextMetadataFilter,
} from './public-types';
import { concatMap, delay, filter, finalize, Observable, of, share, Subject, takeWhile, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { RpcManager } from './rpc.manager';
import { SocketManager } from './socket.manager';
import { generateId } from '../../internal-common/src/public-utils/id-utils';
import {
  AiChatbotAppendContextRequest,
  AiChatbotAskRequest,
  AiChatbotChatRequest,
  AiChatbotDeleteRequest,
  AiChatbotInsertContextRequest,
  AiChatbotInsertInstructionRequest,
  AiChatbotInsertProfileRequest,
  AiChatbotMutateRequest,
  AiChatbotUpdateContextRequest,
  AiChatbotUpdateInstructionRequest,
  AiChatbotUpdateProfileRequest,
} from '../../internal-common/src/types/ai-chatbot.types';
import { AiChatbotMessageToClient, MessageToClient } from '../../internal-common/src/types/socket.types';
import { base64ToFile } from './file-utils';
import { truthy } from 'assertic';

const DEFAULT_CHAT_OPTIONS: AiChatbotChatOptions = {
  smoothTyping: true,
  disableHistory: false,
  includeReference: false,
  responseFormat: 'text',
};

export interface TranscribeAndChatResponse {
  transcribedPrompt: string;
  responseStream: Observable<string>;
}

export interface TranscribeAndAskWithVoiceResponse {
  transcribedPrompt: string;
  responseString: string;
  voiceResponseFile: File;
}

export interface AskWithVoiceResponse {
  responseString: string;
  voiceResponseFile: File;
}

export interface ChatInternalResponse {
  responseStream: Observable<string>;
  serverResponse?: Promise<AiTranscribeAndChatResponse>;
}

export type ChatOptionsWithoutVoice = Omit<AiChatbotChatOptions, 'voiceOptions'>;
export type AskOptionsWithoutVoice = Omit<AiChatbotChatOptions, 'voiceOptions' | 'smoothTyping'>;

export class AiAgentClient {
  private readonly ongoingChatSequences: Record<
    ClientRequestId,
    Subject<{
      value: string;
      complete: boolean;
      tokenIndex?: number;
    }>
  > = {};

  /** @internal */
  constructor(
    private readonly rpcManager: RpcManager,
    private readonly socketManager: SocketManager,
    private readonly integrationId: IntegrationId,
  ) {
    this.socketManager
      .observeNotifications()
      .pipe(
        filter((notification: MessageToClient) => notification.type === 'aiChatbot'),
        map(n => n as AiChatbotMessageToClient),
      )
      .subscribe(notification => {
        this.handleChatResponse(notification).then();
      });
  }

  /**
   * Retrieves a profile reference for the provided id. A profile reference
   * can be used to create and update profiles, add instructions and context
   * and start chats.
   *
   * @param id - The id of the profile.
   * @returns The profile reference.
   */
  profile(id: string): AiAgentReference {
    return new AiAgentReference(this, this.integrationId, id);
  }

  /** @internal */
  async mutate(request: AiChatbotMutateRequest, file?: File): Promise<void> {
    await this.rpcManager.post('ai/chatbot/mutate', request, file ? [file] : []);
  }

  /**
   * Sends a prompt to the specified profile id.
   *
   * @param profileId - The profile id.
   * @param prompt - The prompt.
   * @param options - The options to send to the chat model.
   * @returns An observable that emits when a new response token is received. The emitted value is the entire response
   * that has been received so far.
   */
  chat(profileId: string, prompt: string, options?: ChatOptionsWithoutVoice): Observable<string> {
    return this.chatInternal(profileId, prompt, options).responseStream;
  }

  async transcribeAndChat(
    profileId: string,
    fileToTranscribe: File,
    options?: ChatOptionsWithoutVoice,
  ): Promise<TranscribeAndChatResponse> {
    const resp = this.chatInternal(profileId, fileToTranscribe, options);
    const serverResponse = await truthy(resp.serverResponse, 'TRANSCRIPTION_RESPONSE_NOT_FOUND');
    return { responseStream: resp.responseStream, transcribedPrompt: serverResponse.transcribedPrompt };
  }

  private chatInternal(
    profileId: string,
    prompt: string | File,
    options?: ChatOptionsWithoutVoice,
  ): ChatInternalResponse {
    if (options?.contextMetadataFilter) {
      validateAiContextMetadataFilter(options.contextMetadataFilter);
    }
    const clientRequestId = generateId();

    options = { ...DEFAULT_CHAT_OPTIONS, ...(options || {}) };
    const smoothTyping = options.smoothTyping === undefined ? true : options.smoothTyping;
    let accumulatedValue = '';
    const subject = new Subject<string>();
    const tokenSequence = new Subject<{ value: string; complete: boolean; tokenIndex?: number }>();

    this.ongoingChatSequences[clientRequestId] = tokenSequence;
    let lastTokenIndex: number = -1;
    tokenSequence
      .pipe(
        tap(({ tokenIndex }) => {
          if (tokenIndex !== undefined && tokenIndex < lastTokenIndex) {
            console.warn('Received token index out of order', tokenIndex, lastTokenIndex);
          }
          tokenIndex !== undefined && (lastTokenIndex = tokenIndex);
        }),
        concatMap(({ value, complete }) => {
          if (complete) {
            return of({ value, complete });
          }

          return of(value).pipe(
            smoothTyping ? delay(0) : tap(),
            map(str => ({ value: str, complete: false })),
          );
        }),
        takeWhile(({ complete }) => !complete, true),
      )
      .subscribe({
        next: ({ value }) => {
          accumulatedValue += value;
          subject.next(accumulatedValue);
        },
        error: e => {
          console.error(e);
        },
        complete: () => {
          subject.complete();
        },
      });

    const promptIsString = typeof prompt === 'string';

    const request: AiChatbotChatRequest = {
      profileId,
      prompt: promptIsString ? prompt : undefined,
      options,
      integrationId: this.integrationId,
      clientRequestId,
    };

    const stream = subject.pipe(
      finalize(() => {
        delete this.ongoingChatSequences[clientRequestId];
      }),
      share(),
    );
    const response: ChatInternalResponse = { responseStream: stream };
    if (promptIsString) {
      this.rpcManager.post<string>('ai/chatbot/chat', request).catch(e => {
        subject.error(e);
        subject.complete();
      });
    } else {
      response.serverResponse = this.rpcManager
        .post<AiTranscribeAndChatResponse>('ai/chatbot/transcribeAndChat', request, [prompt as File], 'file')
        .catch(e => {
          subject.error(e);
          subject.complete();
          throw e;
        });
    }

    return response;
  }

  search(agentId: string, options: AiSearchOptions): Promise<AiSearchResponse> {
    const request: AiSearchRequest = {
      options,
      integrationId: this.integrationId,
      profileId: agentId,
    };
    return this.rpcManager.post<AiSearchResponse>('ai/chatbot/search', request);
  }

  /**
   * Sends a prompt to the specified profile id and returns the response as a Promise of string.
   *
   * @param profileId - The profile id.
   * @param prompt - The prompt.
   * @param options - The options to send to the chat model.
   * @returns A promise that resolves when the chat is complete. The resolved value is the entire response.
   */
  async ask(profileId: string, prompt: string, options?: AskOptionsWithoutVoice): Promise<string> {
    const response = await this.askInternal<AiAskResponse>(profileId, prompt, false, options);
    return response.responseString;
  }

  async transcribeAndAsk(
    profileId: string,
    fileToTranscribe: File,
    options?: AskOptionsWithoutVoice,
  ): Promise<AiTranscribeAndAskResponse> {
    return await this.askInternal<AiTranscribeAndAskResponse>(profileId, fileToTranscribe, false, options);
  }

  async transcribeAndAskWithVoiceResponse(
    profileId: string,
    fileToTranscribe: File,
    options?: Omit<AiChatbotChatOptions, 'smoothTyping'>,
  ): Promise<TranscribeAndAskWithVoiceResponse> {
    const response = await this.askInternal<AiTranscribeAndAskWithVoiceResponse>(
      profileId,
      fileToTranscribe,
      true,
      options,
    );
    const voiceResponse = response.voiceResponse;
    return {
      responseString: response.responseString,
      transcribedPrompt: response.transcribedPrompt,
      voiceResponseFile: base64ToFile(
        voiceResponse.base64File,
        `voice.${voiceResponse.extension}`,
        voiceResponse.mimeType,
      ),
    };
  }

  async askWithVoiceResponse(
    profileId: string,
    prompt: string,
    options?: Omit<AiChatbotChatOptions, 'smoothTyping'>,
  ): Promise<AskWithVoiceResponse> {
    const response = await this.askInternal<AiAskWithVoiceResponse>(profileId, prompt, true, options);
    const voiceResponse = response.voiceResponse;
    return {
      responseString: response.responseString,
      voiceResponseFile: base64ToFile(
        voiceResponse.base64File,
        `voice.${voiceResponse.extension}`,
        voiceResponse.mimeType,
      ),
    };
  }

  async askInternal<T>(
    profileId: string,
    prompt: string | File,
    isVoiceResponse: boolean,
    options?: Omit<AiChatbotChatOptions, 'smoothTyping'>,
  ): Promise<T> {
    if (options?.contextMetadataFilter) {
      validateAiContextMetadataFilter(options.contextMetadataFilter);
    }
    options = { ...DEFAULT_CHAT_OPTIONS, ...(options || {}) };
    const promptIsString = typeof prompt === 'string';
    let endpoint = 'ai/chatbot/';

    if (!promptIsString && !isVoiceResponse) {
      endpoint += 'transcribeAndAsk';
    } else if (!promptIsString && isVoiceResponse) {
      endpoint += 'transcribeAndAskWithVoiceResponse';
    } else if (promptIsString && isVoiceResponse) {
      endpoint += 'askWithVoiceResponse';
    } else {
      endpoint += 'ask';
    }

    const request: AiChatbotAskRequest = {
      profileId,
      prompt: promptIsString ? prompt : undefined,
      options,
      integrationId: this.integrationId,
    };

    const files = promptIsString ? undefined : [prompt as File];
    return await this.rpcManager.post<T>(endpoint, request, files, 'file');
  }

  private async handleChatResponse(message: AiChatbotMessageToClient): Promise<void> {
    const tokenSequence = this.ongoingChatSequences[message.clientRequestId];
    if (!tokenSequence) {
      return;
    }

    const { token, complete, tokenIndex } = message.payload;

    if (complete && !token.length) {
      tokenSequence.next({ value: '', complete: true, tokenIndex: undefined });
    } else {
      // If this matches, this is an image or a link markdown
      if (token.match(/\[.*?]\((.*?)\)/g)) {
        tokenSequence.next({
          value: token,
          complete,
          tokenIndex: tokenIndex === undefined ? undefined : tokenIndex,
        });
      } else {
        for (let i = 0; i < token.length; i++) {
          tokenSequence.next({
            value: token[i],
            complete: complete && i === token.length - 1,
            tokenIndex: tokenIndex === undefined ? undefined : tokenIndex,
          });
        }
      }
    }
  }
}

export interface AiChatBotContextData {
  title: string;
  context: AiChatbotContext;
}

export interface ProfileData {
  modelName: AiChatModelName;
  /** If the profile is public, there is no need to write a security rule to access the chat functionality. This can be dangerous, use with caution. */
  isPublic: boolean;
}

export interface InstructionData {
  instruction: string;
}

export class AiAgentReference {
  /** @internal */
  constructor(
    private readonly client: AiAgentClient,
    private readonly integrationId: IntegrationId,
    private readonly profileId: string,
  ) {}

  /**
   * Sends a prompt to the current profile.
   *
   * @param prompt - The prompt.
   * @param options - The chat options.
   * @returns An observable that emits when a new response token is received. The emitted value is the entire response
   * that has been received so far.
   */
  chat(prompt: string, options?: AiChatbotChatOptions): Observable<string> {
    return this.client.chat(this.profileId, prompt, options);
  }

  async transcribeAndChat(fileToTranscribe: File, options?: AiChatbotChatOptions): Promise<TranscribeAndChatResponse> {
    return await this.client.transcribeAndChat(this.profileId, fileToTranscribe, options);
  }

  /**
   * Sends a prompt to the current profile and returns the response as a Promise of string.
   * @param prompt - The prompt.
   * @param options - The chat options.
   */
  ask(prompt: string, options?: Omit<AiChatbotChatOptions, 'smoothTyping'>): Promise<string> {
    return this.client.ask(this.profileId, prompt, options);
  }

  async transcribeAndAsk(
    fileToTranscribe: File,
    options?: AskOptionsWithoutVoice,
  ): Promise<AiTranscribeAndAskResponse> {
    return await this.client.transcribeAndAsk(this.profileId, fileToTranscribe, options);
  }

  async transcribeAndAskWithVoiceResponse(
    fileToTranscribe: File,
    options?: Omit<AiChatbotChatOptions, 'smoothTyping'>,
  ): Promise<TranscribeAndAskWithVoiceResponse> {
    return await this.client.transcribeAndAskWithVoiceResponse(this.profileId, fileToTranscribe, options);
  }

  async askWithVoiceResponse(
    prompt: string,
    options?: Omit<AiChatbotChatOptions, 'smoothTyping'>,
  ): Promise<AskWithVoiceResponse> {
    return await this.client.askWithVoiceResponse(this.profileId, prompt, options);
  }

  search(options: AiSearchOptions): Promise<AiSearchResponse> {
    return this.client.search(this.profileId, options);
  }

  /**
   * Retrieves a context reference for the current profile. A context reference can be used to add a new context entry
   * to the profile, or update/delete an existing entry context.
   *
   * @param id - The id of the context entry. If no id is passed, an id will be
   * generated and the reference will point to a new context entry.
   * @returns The context reference.
   */
  context(id?: string): AiAgentContextReference {
    return new AiAgentContextReference(this.client, this.integrationId, this.profileId, id);
  }

  /**
   * Retrieves an instruction reference for the current profile. An instruction reference can be used to add a new
   * instruction entry to the profile, or update/delete an existing instruction entry.
   *
   * @param id - The id of the instruction entry. If no id is passed, an id will be
   * generated and the reference will point to a new instruction entry.
   * @returns The instruction reference.
   */
  instruction(id?: string): AiAgentInstructionReference {
    return new AiAgentInstructionReference(this.client, this.integrationId, this.profileId, id);
  }

  /**
   * Adds a new profile to the chatbot. This will result in an error if a profile already exists with the same id.
   *
   * @param data An object containing options for creating the profile.
   * @param data.modelName - The name of the AI model (`gpt-3.5, `gpt-4`, `claude-3-opus-20240229`,
   *   `claude-3-sonnet-20240229`, `claude-3-5-sonnet-20240620` or `claude-3-haiku-20240307`, `gemini-pro`,
   *   `gemini-1.5-pro` `gemini-1.5-flash`).
   * @param data.isPublic - Whether the chat functionality of the profile can be accessed without security rules.
   * @returns A promise that resolves when the profile is successfully created.
   */
  insert(data: ProfileData): Promise<void> {
    // TODO: Investigate support strictContext.
    const { modelName, isPublic = false } = data;
    const request: AiChatbotInsertProfileRequest = {
      type: 'insert',
      resource: 'profile',
      profileId: this.profileId,
      payload: {
        modelName,
        isPublic,
        strictContext: false,
      },

      integrationId: this.integrationId,
    };
    return this.client.mutate(request);
  }

  /**
   * Updates an existing chatbot profile. This will result in an error if a profile has not yet been created for the
   * current profile id.
   *
   * @param data An object containing options for updating the profile.
   * @param data.modelName - The name of the OpenAI model (`gpt-3.5, `gpt-4`, `claude-3-opus-20240229`,
   *   `claude-3-sonnet-20240229`, `claude-3-5-sonnet-20240620` or `claude-3-haiku-20240307`, `gemini-pro`,
   *   `gemini-1.5-pro` `gemini-1.5-flash`).
   * @param data.isPublic - Whether the chat functionality of the profile can be accessed without security rules.
   * @returns A promise that resolves when the profile is successfully updated.
   */
  update(data: Partial<ProfileData>): Promise<void> {
    const { modelName, isPublic } = data;
    const request: AiChatbotUpdateProfileRequest = {
      type: 'update',
      resource: 'profile',
      profileId: this.profileId,
      payload: {
        modelName,
        isPublic,
        strictContext: false,
      },

      integrationId: this.integrationId,
    };
    return this.client.mutate(request);
  }

  /**
   * Deletes an existing chatbot profile. This will result in an error if a profile has not yet been created for the
   * current profile id.
   *
   * @returns A promise that resolves when the profile is successfully deleted.
   */
  delete(): Promise<void> {
    const request: AiChatbotDeleteRequest = {
      type: 'delete',
      resource: 'profile',
      profileId: this.profileId,
      integrationId: this.integrationId,
      payload: {},
    };
    return this.client.mutate(request);
  }
}

export class AiAgentContextReference {
  private readonly id;

  /** @internal */
  constructor(
    private readonly client: AiAgentClient,
    private readonly integrationId: IntegrationId,
    private readonly profileId: string,
    id?: string,
  ) {
    this.id = id || generateId();
  }

  /**
   * Adds a new context entry to the chatbot profile. This will result in an error if an entry already exists with
   * the same id.
   *
   * @param data An object containing options for creating the entry.
   * @param data.title - The title of the entry.
   * @param data.context - The context data.
   * @param file - The file to insert.
   * @returns A promise that resolves when the context is successfully created.
   */
  insert(data: AiChatBotContextData, file?: File): Promise<void> {
    if (data.context.metadata) {
      validateAiContextMetadata(data.context.metadata);
    }
    const { title, context } = data;
    const request: AiChatbotInsertContextRequest = {
      type: 'insert',
      resource: 'context',
      profileId: this.profileId,
      integrationId: this.integrationId,
      payload: {
        id: this.id,
        title,
        context,
      },
    };
    return this.client.mutate(request, file);
  }

  /**
   * Appends data to an existing context entry on the chatbot profile. This will result in an error if an entry has not
   * @param data An object containing options for appending the entry.
   * @param file - The file to append.
   */
  append(data: AiChatBotContextData, file?: File): Promise<void> {
    if (data.context.metadata) {
      validateAiContextMetadata(data.context.metadata);
    }
    const { title, context } = data;
    const request: AiChatbotAppendContextRequest = {
      type: 'append',
      resource: 'context',
      profileId: this.profileId,
      integrationId: this.integrationId,
      payload: {
        id: this.id,
        title,
        context,
      },
    };
    return this.client.mutate(request, file);
  }

  /**
   * Updates an existing context entry on the chatbot profile. This will result in an error if an entry has not yet
   * been created for the current context id.
   *
   * @param data An object containing options for updating the entry.
   * @param data.title - The title of the entry.
   * @param data.context - The context data.
   * @returns A promise that resolves when the context is successfully updated.
   */
  update(data: Partial<AiChatBotContextData>): Promise<void> {
    if (data?.context?.metadata) {
      validateAiContextMetadata(data.context.metadata);
    }

    const { title, context } = data;
    const request: AiChatbotUpdateContextRequest = {
      type: 'update',
      resource: 'context',
      profileId: this.profileId,
      integrationId: this.integrationId,
      payload: {
        id: this.id,
        title,
        context,
      },
    };
    return this.client.mutate(request);
  }

  /**
   * Deletes an existing context entry on the chatbot profile. This will result in an error if an entry has not yet
   * been created for the current context id.
   *
   * @returns A promise that resolves when the context is successfully deleted.
   */
  delete(): Promise<void> {
    const request: AiChatbotDeleteRequest = {
      type: 'delete',
      resource: 'context',
      profileId: this.profileId,
      integrationId: this.integrationId,
      payload: {
        id: this.id,
      },
    };
    return this.client.mutate(request);
  }
}

export class AiAgentInstructionReference {
  private readonly id;

  /** @internal */
  constructor(
    private readonly client: AiAgentClient,
    private readonly integrationId: IntegrationId,
    private readonly profileId: string,
    id?: string,
  ) {
    this.id = id || generateId();
  }

  /**
   * Adds a new instruction entry to the chatbot profile. This will result in an error if an entry already exists with
   * the same id.
   *
   * @param data An object containing options for creating the entry.
   * @param data.instruction - The instruction data.
   * @returns A promise that resolves when the instruction is successfully created.
   */
  insert(data: InstructionData): Promise<void> {
    const { instruction } = data;
    const request: AiChatbotInsertInstructionRequest = {
      type: 'insert',
      resource: 'instruction',
      profileId: this.profileId,
      integrationId: this.integrationId,
      payload: {
        id: this.id,
        instruction,
      },
    };
    return this.client.mutate(request);
  }

  /**
   * Updates an existing instruction entry on the chatbot profile. This will result in an error if an entry has not
   * yet been created for the current instruction id.
   *
   * @param data An object containing options for updating the entry.
   * @param data.instruction - The instruction data.
   * @returns A promise that resolves when the instruction is successfully updated.
   */
  update(data: InstructionData): Promise<void> {
    const { instruction } = data;
    const request: AiChatbotUpdateInstructionRequest = {
      type: 'update',
      resource: 'instruction',
      profileId: this.profileId,
      integrationId: this.integrationId,
      payload: {
        id: this.id,
        instruction,
      },
    };
    return this.client.mutate(request);
  }

  /**
   * Deletes an existing instruction entry on the chatbot profile. This will result in an error if an entry has not
   * yet been created for the current instruction id.
   *
   * @returns A promise that resolves when the instruction is successfully deleted.
   */
  delete(): Promise<void> {
    const request: AiChatbotDeleteRequest = {
      type: 'delete',
      resource: 'instruction',
      profileId: this.profileId,
      integrationId: this.integrationId,
      payload: {
        id: this.id,
      },
    };
    return this.client.mutate(request);
  }
}

export class AiChatbotProfileReference extends AiAgentReference {}
export class AiChatbotClient extends AiAgentClient {}
export class AiChatbotContextReference extends AiAgentContextReference {}
export class AiChatbotInstructionReference extends AiAgentInstructionReference {}
