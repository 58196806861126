import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { firstValueFrom, take } from 'rxjs';
import { DocumentationWithComponentDialogComponent } from '@squidcloud/console-web/app/application/documentation-with-component-dialog/documentation-with-component-dialog.component';
import { DialogWithForm, DialogWithFormComponent } from '../components/dialog-with-form/dialog-with-form.component';
import { requiredTextValidator } from '../utils/validators';
import {
  DocumentationDialogComponent,
  DocumentationDialogData,
} from '@squidcloud/console-web/app/application/documentation-dialog/documentation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class GlobalUiService {
  constructor(private readonly dialog: MatDialog) {}

  showConfirmationDialog(
    title: string,
    textOrTextLines: string | string[],
    submitButtonText: string,
    onSubmitFn: (
      formDetails: Record<string, unknown>,
    ) => Promise<string | undefined | void> | string | undefined | void,
  ): void {
    void this.showDialogWithForm({
      title,
      autoFocus: false,
      onSubmit: onSubmitFn,
      textLines: Array.isArray(textOrTextLines) ? textOrTextLines : [textOrTextLines],
      submitButtonText,
      formElements: [],
      buttonRowClass: 'button_row_with_no_top_margin',
    });
  }

  showDialogWithForm<FormDetailsType = Record<string, unknown>>(
    data: DialogWithForm<FormDetailsType>,
    wide = false,
  ): Promise<FormDetailsType | undefined> {
    const config: MatDialogConfig = {
      maxWidth: wide ? `800px` : '496px',
      width: '100%',
      autoFocus: !!data.autoFocus,
      restoreFocus: false,
      panelClass: ['modal', wide ? 'wide' : ''],
      data,
    };
    return firstValueFrom(this.dialog.open(DialogWithFormComponent, config).afterClosed().pipe(take(1)));
  }

  showDocWithComponentDialog(docComponent: unknown, withBorder = true): void {
    const config: MatDialogConfig = {
      maxWidth: '792px',
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: withBorder ? `documentation_modal` : `documentation_modal_borderless`,
      data: { docComponent },
    };
    this.dialog.open(DocumentationWithComponentDialogComponent, config);
  }

  showDocDialog(data: DocumentationDialogData, maxWidth = 792, withBorder = true): void {
    const config: MatDialogConfig = {
      maxWidth: `${maxWidth}px`,
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: withBorder ? `documentation_modal` : `documentation_modal_borderless`,
      data,
    };
    this.dialog.open(DocumentationDialogComponent, config);
  }

  async showDeleteDialog(
    description: string,
    onSubmitFn: (
      formDetails: Record<string, unknown>,
    ) => Promise<string | undefined | void> | string | undefined | void,
    title = 'Arrrrr you sure?',
    requiredText = 'downwiththeship',
    submitButtonText = 'Delete',
  ): Promise<{ name: string } | undefined> {
    return this.showDialogWithForm<{ name: string }>({
      title,
      autoFocus: true,
      onSubmit: onSubmitFn,
      minRole: 'ADMIN',
      textLines: [description, `Please type <span class="bold">${requiredText}</span> to confirm`],
      submitButtonText,
      formElements: [
        {
          type: 'input',
          required: true,
          nameInForm: 'name',
          label: 'Type here',
          extraValidators: [requiredTextValidator(requiredText)],
        },
      ],
    });
  }
}
