<ng-container *ngVar="'ADMIN' | minRole as isAdmin">
  <ng-container *ngVar="selectedProfileSubject | async as selectedProfile">
    <ng-container *ngIf="applicationObs | async as application">
      <ng-container *ngIf="integrationObs | async as integration">
        <div *ngVar="chatbotProfiles | async as schema" class="schema">
          <portal class="flex_shrink_0" [emitter]="headerTemplateChange">
            <integration-header
              [integrationType]="integration.type"
              [title]="isAgent(integration) ? application.name : integration.id"
              [forceSubtitle]="isAgent(integration) ? 'AI Agents' : ''"
            >
            </integration-header>
          </portal>

          <div *ngIf="schema">
            <div class="page_content">
              <div class="left">
                <div class="left_tables">
                  <div class="left_table">
                    <schema-table-header
                      title="Agents"
                      class="mb_16"
                      (add)="showProfileDialog()"
                      [hideAdd]="!hasProfiles()"
                    />
                    @if (hasProfiles()) {
                    <div class="left_rows">
                      @for (profileId of getSortedKeys(schema.profiles); track profileId) {
                      <ng-container *ngVar="schema.profiles[profileId] as profile">
                        <schema-select-row
                          [title]="profileId"
                          icon="robot_icon"
                          (activate)="selectProfile(profileId)"
                          [selected]="profileId === selectedProfile"
                          [attr.data-testid]="'ai-profile-row-' + profile"
                        >
                          @if (profile.isPublic) {
                            <span class="outline_badge is_public">Public</span>
                          }
                        </schema-select-row>
                      </ng-container>
                      }
                    </div>
                    } @else {
                      <div class="left_button">
                        <button (click)="showProfileDialog()"
                                class="primary_button medium_button"
                                data-testid="add-ai-profile-button"
                                mat-flat-button>
                          Add agent
                        </button>
                      </div>
                    }
                  </div>
                </div>
              </div>

              <div class="right">
                <ng-container *ngIf="selectedProfile; else nothingToSee">
                  <ng-container *ngVar="schema.profiles[selectedProfile] as profile">
                    <div class="ai_header_container">
                      <div class="title_and_icon">
                        <mat-icon svgIcon="robot_icon" class="icon"></mat-icon>
                        {{ selectedProfile }}
                      </div>
                      <div class="header_right">
                        <div class="model_name">{{ ModelNameMap[profile.modelName] }}</div>
                        <button class="test_chat_button primary_button medium_button" mat-flat-button
                                (click)="toggleTestChat()"
                                data-testid="test-chat-button"
                        >
                          Test chat
                        </button>
                        <button class="test_chat_button secondary_button medium_button" mat-flat-button
                                (click)="showEmbedWidgetDialog(integration)">
                          Embed widget
                        </button>
                        <button (click)="showProfileDialog(selectedProfile)"
                                class="settings_button secondary_icon_button"
                                mat-flat-button>
                          <mat-icon svgIcon="settings_icon" class="settings_icon" />
                        </button>
                      </div>
                    </div>

                    <info-card
                      class="mb_24"
                      description="Instructions set the rules for how the agent responds and answers questions. They should be direct and simple, and explain the purpose of the agent."
                      rudder="mech"
                      [show]="!hasInstructions(selectedProfile)">
                      <schema-card
                        content
                        titleText="Instructions"
                        icon="api_endpoint_icon"
                        [expanded]="hasInstructions(selectedProfile)"
                        [hideToggle]="!hasInstructions(selectedProfile)"
                        (add)="showAddInstructionDialog()"
                        data-testid="ai-chatbot-instructions-card"
                      >
                        @for (entry of getEntries(profile.instructions); track entry[0]) {
                        <schema-card-item
                          (delete)="showDeleteInstructionDialog(entry[0])"
                          (edit)="showEditInstructionDialog(selectedProfile, entry[0], entry[1])"
                        >
                          <div class="card_item instruction_item">
                            <span class="title">{{ entry[1] }}</span>
                          </div>
                        </schema-card-item>
                        }
                      </schema-card>
                    </info-card>

                    <info-card
                      class="mb_24"
                      description="Context tells the agent what knowledge to pull from when answering questions. Adding context allows the agent to provide relevant answers on specific topics that may not be part of the underlying AI model."
                      rudder="mech_peeking"
                      [show]="!hasContext(selectedProfile)">
                      <schema-card
                        content
                        titleText="Context"
                        icon="api_endpoint_icon"
                        [expanded]="hasContext(selectedProfile)"
                        [hideToggle]="!hasContext(selectedProfile)"
                        (add)="showAddContextDialog()">
                        @for (entry of getEntries(profile.contexts); track entry[0]) {
                        <schema-card-item (delete)="showDeleteContextDialog(entry[0])">
                          <div class="card_item context_item">
                            <span class="title">
                              <span>{{ entry[1].title }}</span>
                              <span class="id">(ID: {{ entry[0] }})</span>
                            </span>
                            <p class="description">{{ entry[1].text }}</p>
                          </div>
                        </schema-card-item>
                        }
                      </schema-card>
                    </info-card>
                  </ng-container>
                </ng-container>
                <ng-template #nothingToSee>
                  <illustration
                    title="Arrrr, I am an empty vessel"
                    description="Build your own AI agents with custom instructions and persistent contexts."
                    icon="rudder_mechanical"
                    [withBackground]="true"
                  />
                </ng-template>
                <div class="danger_zone" *ngIf="isAdmin && selectedProfile">
                  <action-card
                    title="Delete agent"
                    description="Once this profile is deleted there is no going back."
                    (action)="showDeleteProfileDialog(selectedProfile)"
                    cta="Delete this agent"
                    [warning]="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
