import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getRequiredPageParameter, INTEGRATION_ID_PARAMETER } from '@squidcloud/console-web/app/utils/http-utils';
import { IntegrationService } from '@squidcloud/console-web/app/integrations/integration.service';
import {
  StorylaneDemo,
  StorylaneDialogService,
} from '@squidcloud/console-web/app/global/components/storylane-dialog/storylane-dialog.service';

@Component({
  selector: 'how-to-use',
  templateUrl: './how-to-use.component.html',
  styleUrl: './how-to-use.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HowToUseComponent {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly integrationService: IntegrationService,
    private readonly storylane: StorylaneDialogService,
  ) {}

  async handleClick(event: Event): Promise<void> {
    event.stopPropagation();

    const integrationId = getRequiredPageParameter(INTEGRATION_ID_PARAMETER, this.activatedRoute.snapshot);
    const integration = await this.integrationService.getIntegration(integrationId);
    if (!integration) return;

    switch (integration.type) {
      case 'ai_agents':
      case 'ai_chatbot':
        void this.storylane.play(StorylaneDemo.AI_HOW_TO);
        break;
      default:
        throw new Error(`Not implemented for integration type ${integration.type}`);
        break;
    }
  }
}
