import { ChangeDetectionStrategy, Component, EventEmitter, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, filter, Observable, take, takeUntil } from 'rxjs';
import { SnackBarService } from '../../../global/services/snack-bar.service';
import { GlobalUiService } from '../../../global/services/global-ui.service';
import { IntegrationService } from '../../integration.service';
import { GraphQLSchemaService } from './graphql-schema.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getRequiredPageParameter, INTEGRATION_ID_PARAMETER } from '@squidcloud/console-web/app/utils/http-utils';
import { IntegrationGraphQLSchema } from '@squidcloud/internal-common/types/integrations/api.types';
import { GraphQLIntegrationConfig } from '@squidcloud/internal-common/types/integrations/schemas';

@Component({
  selector: 'graphql-schema',
  templateUrl: './graphql-schema.component.html',
  styleUrls: ['./graphql-schema.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphqlSchemaComponent {
  readonly integrationObs: Observable<GraphQLIntegrationConfig>;
  readonly schemaObs: Observable<IntegrationGraphQLSchema | undefined>;

  @Output() headerTemplateChange = new EventEmitter<TemplateRef<unknown>>();

  readonly activatedSubject = new BehaviorSubject(false);
  readonly headersPromise: Promise<Record<string, string>>;

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly integrationService: IntegrationService,
    private readonly graphqlSchemaService: GraphQLSchemaService,
    private readonly globalUiService: GlobalUiService,
    private readonly snackBar: SnackBarService,
  ) {
    const integrationId = getRequiredPageParameter(INTEGRATION_ID_PARAMETER, activatedRoute.snapshot);
    this.integrationObs = this.integrationService.observeIntegration(
      integrationId,
    ) as Observable<GraphQLIntegrationConfig>;
    this.schemaObs = this.graphqlSchemaService.observeSchema();
    this.integrationObs.pipe(take(1), takeUntilDestroyed()).subscribe(async (integration: GraphQLIntegrationConfig) => {
      await graphqlSchemaService.initializeSchema(integration);
    });

    activatedRoute.url.pipe(takeUntil(this.activatedSubject.pipe(filter(Boolean)))).subscribe(url => {
      this.activatedSubject.next(!!url.find(({ path }) => path === 'schema'));
    });

    this.headersPromise = this.graphqlSchemaService.getHeaders();
  }

  get graphqlUrl(): string {
    return this.graphqlSchemaService.getUrl();
  }

  showRediscoverSchemaDialog(): void {
    this.globalUiService.showConfirmationDialog(
      'Rediscover Schema',
      [
        'Are you sure you want to rediscover the schema? This action may overwrite data in the saved schema.',
        'Note that these changes are not saved automatically.',
      ],
      'Confirm',
      async () => {
        await this.discoverSchema();
      },
    );
  }

  async discoverSchema(): Promise<void> {
    try {
      await this.graphqlSchemaService.discoverSchema();
      this.snackBar.success('Schema discovered');
    } catch (e) {
      console.error('Unable to discover schema', e);
      this.snackBar.warning('Unable to discover schema, please try again later');
    }
  }
}
