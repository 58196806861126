import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from '../application/application.service';
import { GlobalUiService } from '../global/services/global-ui.service';
import { BackendService } from './backend.service';
import { ExecutablesTutorialComponent } from './executables/executables-tutorial/executables-tutorial.component';
import { ExecutablesComponent } from './executables/executables.component';
import { SchedulersTutorialComponent } from './schedulers/schedulers-tutorial/schedulers-tutorial.component';
import { SchedulersComponent } from './schedulers/schedulers.component';
import { SecurityRulesTutorialComponent } from './security-rules/security-rules-tutorial/security-rules-tutorial.component';
import { SecurityRulesComponent } from './security-rules/security-rules.component';
import { TriggersTutorialComponent } from './triggers/triggers-tutorial/triggers-tutorial.component';
import { TriggersComponent } from './triggers/triggers.component';
import { WebhooksTutorialComponent } from './webhooks/webhooks-tutorial/webhooks-tutorial.component';
import { WebhooksComponent } from './webhooks/webhooks.component';
import { getPageParameter, TAB_ID_PARAMETER } from '@squidcloud/console-web/app/utils/http-utils';
import { OpenApiComponent } from '@squidcloud/console-web/app/backend/openapi/openapi.component';
import { OpenApiTutorialComponent } from '@squidcloud/console-web/app/backend/openapi/openapi-tutorial/openapi-tutorial.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AngularComponent = any;

interface BackendTab {
  id: string;
  name: string;
  component: AngularComponent;
  tutorialComponent: AngularComponent;
}

@Component({
  templateUrl: './backend.component.html',
  styleUrls: ['./backend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackendComponent {
  selectedIndex = 0;
  backendMetadataAvailableObs = this.backendService.observeBackendMetadataAvailable();
  tabs: Array<BackendTab> = [
    {
      id: 'webhooks',
      name: 'Webhooks',
      component: WebhooksComponent,
      tutorialComponent: WebhooksTutorialComponent,
    },
    {
      id: 'openapi',
      name: 'OpenAPI',
      component: OpenApiComponent,
      tutorialComponent: OpenApiTutorialComponent,
    },
    {
      id: 'triggers',
      name: 'Triggers',
      component: TriggersComponent,
      tutorialComponent: TriggersTutorialComponent,
    },
    {
      id: 'executables',
      name: 'Executables',
      component: ExecutablesComponent,
      tutorialComponent: ExecutablesTutorialComponent,
    },
    {
      id: 'schedulers',
      name: 'Schedulers',
      component: SchedulersComponent,
      tutorialComponent: SchedulersTutorialComponent,
    },
    {
      id: 'securityRules',
      name: 'Security rules',
      component: SecurityRulesComponent,
      tutorialComponent: SecurityRulesTutorialComponent,
    },
  ];

  private readonly tabIdToIndexMap: Record<string, number> = this.tabs.reduce(
    (acc, tab, index) => {
      acc[tab.id] = index;
      return acc;
    },
    {} as Record<string, number>,
  );

  private readonly indexToTabIdMap: Record<number, string> = Object.entries(this.tabIdToIndexMap).reduce(
    (acc, [key, value]) => {
      acc[value] = key;
      return acc;
    },
    {} as Record<number, string>,
  );

  readonly applicationObs = this.applicationService.observeCurrentApplication();

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly globalUiService: GlobalUiService,
    private readonly backendService: BackendService,
    private readonly applicationService: ApplicationService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
  ) {
    activatedRoute.params.subscribe(() => {
      const tabId = getPageParameter(TAB_ID_PARAMETER, activatedRoute.snapshot) || this.indexToTabIdMap[0];
      this.selectedIndex = this.tabIdToIndexMap[tabId];
      this.cdr.markForCheck();
    });
  }

  async changeUrl(index: number | null): Promise<void> {
    if (index === null) index = 0;
    await this.router.navigate([
      '/application',
      this.applicationService.getCurrentApplicationOrFail().appId,
      'backend',
      this.indexToTabIdMap[index],
    ]);
  }

  showDocDialog(tutorialComponent: AngularComponent): void {
    this.globalUiService.showDocWithComponentDialog(tutorialComponent);
  }
}
