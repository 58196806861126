import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ApiEndpoint,
  ApiEndpointId,
  ApiInjectionSchema,
  HttpMethod,
  IntegrationApiEndpoints,
} from '@squidcloud/client';
import { BehaviorSubject, combineLatest, filter, Observable, take, tap } from 'rxjs';
import { IntegrationService } from '../../integration.service';

import { GlobalUiService } from '../../../global/services/global-ui.service';
import { SnackBarService } from '../../../global/services/snack-bar.service';
import { Modifications } from '../utils/modifications';
import { ApiSchemaService, ApiTransactionType } from './api-schema.service';
import {
  getEndpointFormElements,
  getRequestFieldFormElements,
  getResponseFieldFormElements,
  RequestFieldForm,
  ResponseFieldForm,
} from './utils/dialog';
import { truthy } from 'assertic';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getRequiredPageParameter, INTEGRATION_ID_PARAMETER } from '@squidcloud/console-web/app/utils/http-utils';
import { IntegrationApiSchema } from '@squidcloud/internal-common/types/integrations/api.types';
import { NavigationService } from '@squidcloud/console-web/app/utils/navigation.service';
import { copy } from '@squidcloud/console-web/app/utils/copy-utils';
import { getEntries, getSortedKeys } from '@squidcloud/console-web/app/utils/angular-utils';
import { numberString, plural, titleCase } from '@squidcloud/internal-common/utils/string';
import { HttpApiIntegrationConfig } from '@squidcloud/internal-common/types/integrations/schemas';

export interface SelectedFields {
  endpoint?: EndpointField;
  request?: RequestFields;
  response?: ResponseFields;
}

export interface EndpointField {
  name: string;
  baseUrl: string;
  relativePath: string;
  method: HttpMethod;
  tags?: Array<string>;
}

export interface RequestFields {
  modified: boolean;
  fields: Array<{ name: string; location: string; description?: string }>;
}

export interface ResponseFields {
  modified: boolean;
  fields: Array<{ name: string; location: string; path: string; description?: string }>;
}

type InfoCard = {
  dismissed: boolean;
  request: boolean;
  response: boolean;
};

@Component({
  selector: 'api-schema',
  templateUrl: '/api-schema.component.html',
  styleUrls: ['./api-schema.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiSchemaComponent {
  readonly integrationObs: Observable<HttpApiIntegrationConfig>;
  readonly schemaObs: Observable<IntegrationApiSchema | undefined>;
  readonly modificationsObservable: Observable<Modifications>;
  readonly integrationId: string;

  @Input() isNewSchema!: boolean;
  @Output() headerTemplateChange = new EventEmitter<TemplateRef<unknown>>();
  foldersOpen: Record<string, boolean> = {};

  selectedEndpointSubject = new BehaviorSubject<string | undefined>(undefined);
  selectedBaseUrlSubject = new BehaviorSubject<string | undefined>(undefined);

  searchValue: string | undefined = undefined;
  selectedFields: SelectedFields | undefined = undefined;
  infoCard: InfoCard = {
    dismissed: false,
    request: false,
    response: false,
  };

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly navigationService: NavigationService,
    private readonly integrationService: IntegrationService,
    private readonly apiSchemaService: ApiSchemaService,
    private readonly cdr: ChangeDetectorRef,
    private readonly globalUiService: GlobalUiService,
    private readonly snackBar: SnackBarService,
  ) {
    this.integrationId = getRequiredPageParameter(INTEGRATION_ID_PARAMETER, activatedRoute.snapshot);
    this.integrationObs = this.integrationService.observeIntegration(
      this.integrationId,
    ) as Observable<HttpApiIntegrationConfig>;
    this.schemaObs = this.apiSchemaService.observeSchema().pipe(
      tap(schema => {
        if (schema === undefined) {
          this.apiSchemaService.setEmptySchema();
        }
      }),
    );
    this.modificationsObservable = this.apiSchemaService.observeModifications();
    this.integrationObs.pipe(take(1), takeUntilDestroyed()).subscribe(async (integration: HttpApiIntegrationConfig) => {
      await apiSchemaService.initializeSchema(integration);
    });

    // This should only run once when the component loads
    this.schemaObs
      .pipe(
        filter(schema => {
          return Object.keys(schema?.endpoints || {}).length > 0;
        }),
        take(1),
        takeUntilDestroyed(),
      )
      .subscribe(schema => {
        this.selectFirstEndpoint(schema);
      });

    // This should run every time the schema or modifications change.
    combineLatest([this.modificationsObservable, this.schemaObs.pipe(filter(Boolean))])
      .pipe(takeUntilDestroyed())
      .subscribe(([, schema]) => {
        this.setSelectedFields(schema);
      });
  }

  get isOpenApiIntegration(): boolean {
    return this.apiSchemaService.isOpenApiIntegration;
  }

  selectEndpoint(endpointId: string | undefined): void {
    this.selectedEndpointSubject.next(endpointId);
    this.selectedBaseUrlSubject.next(undefined);
    this.setSelectedEndpointFields(endpointId ? this.apiSchemaService.getSchemaOrFail() : undefined);
    this.cdr.markForCheck();
  }

  selectBaseUrl(baseUrl: string | undefined): void {
    this.selectedBaseUrlSubject.next(baseUrl);
    this.selectedEndpointSubject.next(undefined);
    this.setSelectedFields(baseUrl ? this.apiSchemaService.getSchemaOrFail() : undefined);
  }

  get baseUrl(): string {
    const schema = this.apiSchemaService.getSchemaOrFail();
    return schema.baseUrl;
  }

  get hasEndpoints(): boolean {
    const schema = this.apiSchemaService.getSchemaOrFail();
    return !!Object.keys(schema.endpoints || {}).length;
  }

  get selectedEndpoint(): string | undefined {
    return this.selectedEndpointSubject.value;
  }

  get selectedBaseUrl(): string | undefined {
    return this.selectedBaseUrlSubject.value;
  }

  private setSelectedFields(schema?: IntegrationApiSchema): void {
    if (this.selectedBaseUrl) {
      this.selectedFields = {};
    } else if (this.selectedEndpoint) {
      this.setSelectedEndpointFields(schema);
    } else {
      this.selectedFields = undefined;
    }
  }

  private setSelectedEndpointFields(schema?: IntegrationApiSchema): void {
    if (!schema || !this.selectedEndpoint) {
      this.selectedFields = undefined;
      return;
    }

    const endpoint = schema.endpoints[this.selectedEndpoint];
    if (!endpoint) {
      this.selectedFields = undefined;
      return;
    }

    const modifications = this.apiSchemaService.getModifications();

    const endpointFields = {
      name: this.selectedEndpoint,
      baseUrl: this.baseUrl,
      relativePath: `${endpoint.relativePath.startsWith('/') ? endpoint.relativePath : `/${endpoint.relativePath}`}`,
      method: endpoint.method,
      tags: endpoint.tags,
    };
    const requestFields = Object.entries(endpoint.requestSchema || {}).map(([fieldName, responseField]) => {
      return {
        name: fieldName,
        location: responseField.location,
        description: responseField.description,
      };
    });
    const responseFields = Object.entries(endpoint.responseSchema || {}).map(([fieldName, responseField]) => {
      return {
        name: fieldName,
        location: responseField.location,
        path: responseField.path || '',
        description: responseField.description,
      };
    });

    this.infoCard.request = !requestFields.length;
    this.infoCard.response = !responseFields.length;

    this.selectedFields = {
      endpoint: endpointFields,
      request: {
        modified: modifications.isPathModified(['endpoint', this.selectedEndpoint, 'request']),
        fields: requestFields.sort((f1, f2) => f1.name.localeCompare(f2.name)),
      },
      response: {
        modified: modifications.isPathModified(['endpoint', this.selectedEndpoint, 'response']),
        fields: responseFields.sort((f1, f2) => f1.name.localeCompare(f2.name)),
      },
    };
  }

  groupEndpointsByTag(endpoints: IntegrationApiEndpoints): Record<string, IntegrationApiEndpoints | ApiEndpoint> {
    // Record<string, IntegrationApiEndpoints> = contains tag and should be a folder
    // Record<string, ApiEndpoint> = no tag
    const result: Record<string | ApiEndpointId, IntegrationApiEndpoints | ApiEndpoint> = {};

    for (const endpointId in endpoints) {
      if (
        !this.searchValue ||
        (this.searchValue && endpointId.toLowerCase().includes(this.searchValue.toLowerCase()))
      ) {
        const tags = endpoints[endpointId].tags;
        if (tags) {
          const endpointToAdd: IntegrationApiEndpoints = {};

          endpointToAdd[endpointId] = endpoints[endpointId];
          result[tags[0]] = { ...result[tags[0]], ...endpointToAdd };
        } else {
          result[endpointId] = endpoints[endpointId];
        }
      }
    }

    // Initialize foldersOpen array
    for (let i = 0; i < Object.keys(result).length; i++) {
      if (Object.values(Object.values(result)[i])[0]['tags']) {
        const curTag = Object.keys(result)[i];
        if (!(curTag in this.foldersOpen)) {
          this.foldersOpen[curTag] = false;
        }
      }
    }
    return result;
  }

  trackByEndpointId(_: number, endpointId: ApiEndpointId | Array<string | unknown>): ApiEndpointId {
    if (typeof endpointId !== 'string') return endpointId[0] as string;
    return endpointId;
  }

  toggleFolder(tag: string): void {
    this.foldersOpen[tag] = !this.foldersOpen[tag];
  }

  async copyUrl(url: string): Promise<void> {
    await copy(url, 'URL', this.snackBar);
  }

  showEndpointDialog(endpointId?: string): void {
    const schema = this.apiSchemaService.getSchemaOrFail();
    const endpoint = endpointId ? schema.endpoints[endpointId] : undefined;
    const isEdit = !!endpointId;

    this.globalUiService
      .showDialogWithForm<{ name: string; relativePath: string; method: HttpMethod }>({
        title: `${isEdit ? 'Edit' : 'Add'} endpoint`,
        textLines: [],
        submitButtonText: isEdit ? 'Update' : 'Add endpoint',
        formElements: getEndpointFormElements(endpointId, endpoint),
        minRole: 'ADMIN',
        onSubmit: (data): void => {
          if (!data) return;
          this.apiSchemaService.setEndpoint(endpointId, data.name, data.relativePath, data.method);
          this.selectEndpoint(data['name']);
        },
        onDelete: isEdit ? this.showDeleteEndpointDialog.bind(this) : undefined,
      })
      .then();
  }

  searchEndpoints(value: string): void {
    this.searchValue = value;
  }

  showDeleteEndpointDialog(): void {
    this.globalUiService.showConfirmationDialog(
      'Arrrrr you sure?',
      `This will remove the '${this.selectedEndpoint}' endpoint from the schema.`,
      'Delete',
      () => {
        this.apiSchemaService.deleteEndpoint(truthy(this.selectedEndpoint));
        this.selectFirstEndpoint(this.apiSchemaService.getSchemaOrFail());
      },
    );
  }

  showFieldDialog(transactionType: ApiTransactionType, fieldName?: string): void {
    const schema = this.apiSchemaService.getSchemaOrFail();
    const endpoint = this.selectedEndpoint ? schema.endpoints[this.selectedEndpoint] : undefined;
    const isEdit = !!fieldName;

    let formElements;
    switch (transactionType) {
      case 'request':
        formElements = getRequestFieldFormElements(truthy(endpoint), fieldName);
        break;
      case 'response':
        formElements = getResponseFieldFormElements(truthy(endpoint), fieldName);
        break;
    }

    this.globalUiService
      .showDialogWithForm<RequestFieldForm | ResponseFieldForm>({
        title: `${isEdit ? 'Edit' : 'Add'} ${transactionType} field`,
        textLines: [],
        submitButtonText: isEdit ? 'Update' : 'Add field',
        minRole: 'ADMIN',
        formElements,
        onSubmit: (data): void => {
          if (!data) return;
          const name = data.name;
          switch (transactionType) {
            case 'request': {
              const { location } = data as RequestFieldForm;
              this.apiSchemaService.setRequestField(truthy(this.selectedEndpoint), fieldName, name, location);
              break;
            }
            case 'response': {
              const { path, location } = data as ResponseFieldForm;
              this.apiSchemaService.setResponseField(truthy(this.selectedEndpoint), fieldName, name, location, path);
              break;
            }
          }
        },
      })
      .then();
  }

  showDeleteFieldDialog(transactionType: ApiTransactionType, fieldName: string): void {
    let message = '';
    if (this.selectedEndpoint) {
      message = `This will delete ${fieldName} from the ${this.selectedEndpoint} endpoint.`;
    } else if (this.selectedBaseUrl) {
      message = `This will delete ${fieldName} from the ${this.selectedBaseUrl} base URL.`;
    }

    this.globalUiService.showConfirmationDialog('Arrrrr you sure?', message, 'Delete', () => {
      if (this.selectedEndpoint) {
        this.apiSchemaService.deleteEndpointField(this.selectedEndpoint, fieldName, transactionType);
      } else if (this.selectedBaseUrl) {
        this.apiSchemaService.deleteBaseUrlField(this.selectedBaseUrl, fieldName);
      }
    });
  }

  duplicateField(transactionType: ApiTransactionType, fieldName: string): void {
    if (this.selectedEndpoint) {
      this.apiSchemaService.duplicateEndpointField(this.selectedEndpoint, fieldName, transactionType);
    } else if (this.selectedBaseUrl) {
      this.apiSchemaService.duplicateBaseUrlField(this.selectedBaseUrl, fieldName);
    }
  }

  showInfoCard(transactionType?: ApiTransactionType): boolean {
    if (this.infoCard.dismissed) return false;

    switch (transactionType) {
      case 'request':
        return this.infoCard.request && !this.infoCard.response;
      case 'response':
        return this.infoCard.response && !this.infoCard.request;
      default:
        return this.infoCard.request && this.infoCard.response;
    }
  }

  dismissInfoCard(): void {
    this.infoCard.dismissed = true;
  }

  setInjectionSchema(schema: ApiInjectionSchema): void {
    if (this.selectedEndpoint) {
      this.apiSchemaService.setEndpointInjectionSchema(this.selectedEndpoint, schema);
    } else if (this.selectedBaseUrl) {
      this.apiSchemaService.setBaseUrlInjectionSchema(this.selectedBaseUrl, schema);
    }
  }

  showBaseUrlDialog(): void {
    const isEdit = !!this.baseUrl;

    this.globalUiService
      .showDialogWithForm<{ url: string }>({
        title: `${isEdit ? 'Edit' : 'Add'} Base URL`,
        textLines: [],
        submitButtonText: isEdit ? 'Update' : 'Add URL',
        minRole: 'ADMIN',
        formElements: [
          {
            type: 'input',
            label: 'Base URL',
            nameInForm: 'url',
            defaultValue: this.baseUrl,
            required: true,
          },
        ],
        onSubmit: (data): void => {
          if (!data) return;
          this.apiSchemaService.setBaseUrl(data.url.trim());
        },
      })
      .then();
  }

  showUploadSchemaViaFileUploadDialog(): void {
    this.globalUiService
      .showDialogWithForm<ApiFormDetailsType>({
        title: `Upload File`,
        textLines: ['Upload an Open API specification file'],
        submitButtonText: 'Discover Schema',
        minRole: 'ADMIN',
        formElements: [
          {
            type: 'file',
            required: true,
            nameInForm: 'file',
            label: 'File upload',
            fileTypes: 'json and yaml files',
          },
        ],
        onSubmit: async (data: ApiFormDetailsType): Promise<void> => {
          if (!data.file) return;
          await this.apiSchemaService.discoverApiSchemaFromFile(data.file);
        },
      })
      .then();
  }

  private selectFirstEndpoint(schema?: IntegrationApiSchema): void {
    if (!schema) return;
    const endpoints = Object.keys(schema.endpoints || []).sort();
    this.selectEndpoint(endpoints[0]);
  }

  async saveSchema(): Promise<void> {
    try {
      if (!this.baseUrl.startsWith('http://') && !this.baseUrl.startsWith('https://')) {
        this.snackBar.warning(`Invalid URL, the base URL must start with 'http://' or 'https://'`);
        return;
      }
      const schema = this.apiSchemaService.getSchemaOrFail();
      let endpointsWithUnsupportedBody = 0;

      for (const [endpointId, endpoint] of Object.entries(schema.endpoints)) {
        for (const [_, requestField] of Object.entries(endpoint?.requestSchema || {})) {
          if (requestField.location === 'body' && (endpoint.method === 'get' || endpoint.method === 'delete')) {
            this.apiSchemaService.deleteEndpoint(endpointId);
            endpointsWithUnsupportedBody++;
          }
        }
      }

      const navGuard = this.navigationService.newNavigationGuard();
      await this.apiSchemaService.saveSchema();
      const snackbarNote = endpointsWithUnsupportedBody
        ? `. ${titleCase(numberString(endpointsWithUnsupportedBody))} ${plural('endpoint', endpointsWithUnsupportedBody)} ${plural('was', endpointsWithUnsupportedBody, { alt: 'were' })} removed due to unsupported request body parameters.`
        : '';
      this.snackBar.success((this.isNewSchema ? 'Integration added' : 'Schema saved') + snackbarNote);
      if (this.isNewSchema) {
        await navGuard.navigateByUrl('/integrations');
      }
    } catch (e) {
      console.error('Unable to save schema', e);
      this.snackBar.warning('Unable to save schema, please try again later');
      return;
    }
  }

  showRediscoverSchemaDialog(): void {
    this.globalUiService.showConfirmationDialog(
      'Rediscover Schema',
      [
        'Are you sure you want to rediscover the schema? This action fetches the schema from the OpenAPI spec and may overwrite data in the saved schema.',
        'Note that these changes are not saved automatically.',
      ],
      'Confirm',
      async () => {
        await this.discoverSchema();
      },
    );
  }

  async discoverSchema(): Promise<void> {
    try {
      // Do not autosave the discovered schema
      await this.apiSchemaService.discoverSchema();
      this.snackBar.success('Schema discovered');
    } catch (e) {
      console.error('Unable to discover schema', e);
      this.snackBar.warning('Unable to discover schema, please try again later');
    }
  }

  protected readonly getSortedKeys = getSortedKeys;
  protected readonly getEntries = getEntries;

  isApiEndpoint(endpoint: IntegrationApiEndpoints | ApiEndpoint): endpoint is ApiEndpoint {
    return !!endpoint.method && !!endpoint.relativePath;
  }
}

type ApiFormDetailsType = { name: string; file?: File };
