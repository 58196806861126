import {
  AppId,
  DiscoverOpenApiSchemaFromFileRequest,
  DiscoverOpenApiSchemaRequest,
  IntegrationId,
  Squid,
} from '@squidcloud/client';
import { SquidCloudId } from '@squidcloud/console-common/clouds-and-regions';
import {
  Auth0IdentityProviderType,
  CpUser,
  CreateUserRequest,
  CreateUserResponse,
  SendEmailVerificationLinkRequest,
} from '@squidcloud/console-common/types/account.types';
import { CpApplication, RegionAndCloudMapping } from '@squidcloud/console-common/types/application.types';
import {
  BillingPlanTemplateId,
  CpBill,
  GetPaymentDetailsRequest,
  GetPaymentDetailsResponse,
  GetSavePaymentDetailsClientSecretRequest,
} from '@squidcloud/console-common/types/billing.types';
import { UpdateEntityFeatureRequest } from '@squidcloud/console-common/types/feature-service-api';
import { M3Bill } from '@squidcloud/console-common/types/m3-types';
import { GetBackendMetricsRequest, GetBackendMetricsResponse } from '@squidcloud/console-common/types/metrics.types';
import {
  InvitationKey,
  Organization,
  OrganizationDeletionErrorCode,
  OrganizationId,
  OrganizationRole,
} from '@squidcloud/console-common/types/organization.types';
import { FeatureDescription, FeatureId } from '@squidcloud/internal-common/features/features-utils';
import { ApplicationQuotaName, OrganizationQuotaName, QuotaName } from '@squidcloud/internal-common/quota.types';
import { Schema, UpsertIntegrationRequest } from '@squidcloud/internal-common/types/application.types';
import {
  AiChatbotProfiles,
  GetAiChatbotProfilesRequest,
} from '@squidcloud/internal-common/types/integrations/ai_chatbot.types';
import {
  DiscoverGraphQLConnectionSchemaResponse,
  DiscoverOpenApiSchemaFromFileResponse,
  DiscoverOpenApiSchemaResponse,
} from '@squidcloud/internal-common/types/integrations/api.types';
import {
  DiscoverDataConnectionSchemaRequest,
  DiscoverDataConnectionSchemaResponse,
  TestDataConnectionResponse,
} from '@squidcloud/internal-common/types/integrations/database.types';
import {
  BaseIntegrationConfig,
  GraphQLIntegrationConfig,
  IntegrationSchema,
} from '@squidcloud/internal-common/types/integrations/schemas';
import { DeleteSecretRequest, SetSecretRequest } from '@squidcloud/internal-common/types/secret.types';
import { ActivateGcpPurchaseRequest } from '@squidcloud/console-common/types/marketplace-gcp.types';
import { ActivateAwsPurchaseRequest } from '@squidcloud/console-common/types/marketplace-aws.types';

export interface ConsoleBackendExecutableMap {
  // Organization.
  createOrganization: { request: string; response: OrganizationId };
  deleteOrganization: { request: OrganizationId; response: OrganizationDeletionErrorCode | null };

  // Application.
  createApplication: { request: ConsoleCreateApplicationRequest; response: ConsoleCreateApplicationResponse };
  deleteApplication: { request: string; response: void };
  getRegionAndCloudMapping: { request: string; response: RegionAndCloudMapping | undefined };

  // Integration.
  /** Creates or updates an integration. */
  upsertIntegration: { request: ConsoleUpsertIntegrationRequest; response: void };
  /** Deletes an non built-in integration. */
  deleteIntegration: { request: ConsoleDeleteIntegrationRequest; response: void };
  /** Upsert the schema for an integration. */
  upsertIntegrationSchema: { request: ConsoleUpsertIntegrationSchemaRequest; response: void };
  deleteBuiltInCollection: { request: ConsoleDeleteBuiltInCollectionRequest; response: void };
  getSchemaWithHiddenFields: { request: GetSchemaRequest; response: Schema | undefined };
  discoverDataConnectionSchema: {
    request: DiscoverDataConnectionSchemaRequest;
    response: DiscoverDataConnectionSchemaResponse;
  };
  discoverGraphQLConnectionSchema: {
    request: GraphQLIntegrationConfig & { appId: AppId };
    response: DiscoverGraphQLConnectionSchemaResponse;
  };
  discoverOpenApiSchema: {
    request: DiscoverOpenApiSchemaRequest & { appId: AppId };
    response: DiscoverOpenApiSchemaResponse;
  };
  discoverOpenApiSchemaFromFile: {
    request: DiscoverOpenApiSchemaFromFileRequest & { appId: AppId };
    response: DiscoverOpenApiSchemaFromFileResponse;
  };
  testDataConnection: { request: TestDataConnectionRequest; response: TestDataConnectionResponse };
  testGraphQLConnection: { request: TestGraphQLConnectionRequest; response: TestDataConnectionResponse };
  getAiChatbotProfiles: { request: GetAiChatbotProfilesRequest & { appId: AppId }; response: AiChatbotProfiles };

  // Invitation.
  acceptInvitation: { request: string; response: OrganizationId };
  createInvitation: { request: CreateInvitationRequest; response: void };
  resendInvitation: { request: ResendInvitationRequest; response: void };

  // Quota and Usage.
  getOrganizationQuotas: { request: string; response: OrganizationQuotasResponse };
  getApplicationQuotas: { request: ApplicationQuotasRequest; response: ApplicationQuotasResponse };
  getUsageStats: { request: string; response: Record<string, number> };
  getBackendMetrics: { request: GetBackendMetricsRequest; response: GetBackendMetricsResponse };

  // Secrets
  getAppApiKey: { request: string; response: string | undefined };
  setCustomSecret: { request: SetSecretRequest & { appId: AppId }; response: void };
  deleteCustomSecret: { request: DeleteSecretRequest & { appId: AppId }; response: void };

  // Billing.
  /** Returns a list of all M3ter bills for the given organization. */
  getOrganizationM3Bills: { request: string; response: OrganizationM3BillsResponse };
  /** Returns a list of invoiced Console bills for the given organization. */
  getOrganizationCpBills: { request: OrganizationCpBillsRequest; response: OrganizationCpBillsResponse };
  getPaymentDetails: { request: GetPaymentDetailsRequest; response: GetPaymentDetailsResponse | undefined };
  getSavePaymentDetailsClientSecret: { request: GetSavePaymentDetailsClientSecretRequest; response: string };
  /**
   * Approves M3ter bill and returns the updated list of bills.
   * Fails if the bill is not found or can't be approved by any reason.
   * Request is a billId. Returns the updated M3 bill.
   * The function can only be called by sysadmin.
   */
  approveBill: { request: string; response: M3Bill };
  /**
   * Creates an invoice in Stripe for an approved M3 bill and changes M3 bill status to locked.
   * Fails if the bill already has an invoice, if the M3 bill is not in Approved state, or if there is some other
   * unexpected error.
   * Request is a billId. Returns the update CpBill.
   * The function can only be called by sysadmin.
   */
  createInvoice: { request: string; response: CpBill };

  // User.
  /**
   * Creates a new user account.
   * If a user with the same email already exists but the request comes from a different identity provider (IDP),
   * this method will automatically link the existing account with the new one.
   * Note: Automatic linking is secure because Auth0 only allows users with a verified email to access the Console.
   */
  createUser: { request: CreateUserRequest; response: CreateUserResponse };
  sendEmailVerificationLink: { request: SendEmailVerificationLinkRequest; response: void };
  executePostLoginActions: { request: void; response: void };
  unlinkUserAccount: { request: Auth0IdentityProviderType; response: void };

  // Sysadmin.
  searchUsers: { request: string; response: Array<CpUser> };
  searchOrganizations: { request: string; response: Array<Organization> };
  searchApplications: { request: string; response: Array<CpApplication> };
  updateEntityFeature: { request: UpdateEntityFeatureRequest; response: void };
  getFeatureDescriptions: { request: void; response: Record<FeatureId, FeatureDescription> };

  /** Resets organization quotas to the plan default values. */
  resetOrganizationQuotasBySysadmin: { request: string; response: void };

  /** Resets  application quotas to the plan default values. */
  resetApplicationQuotasBySysadmin: { request: string; response: void };

  /**
   * Update a single quota maximum and available values or completely removes the quota.
   * Can only be called by sysadmin.
   */
  updateQuotaBySysadmin: { request: UpdateQuotaBySysadminRequest; response: void };

  /**
   * Updates organization billing plan.
   * Resets Redis (Quotas/Rate limits) & M3ter states.
   */
  changeBillingPlanBySysadmin: { request: ChangeBillingPlanBySysadminRequest; response: void };

  /**
   * Completely deletes organization quotas and rate limits.
   * Quotas will be reset on the next billing plan period.
   */
  deleteOrganizationQuotasBySysadmin: { request: string; response: void };

  /**
   * Completely deletes application quotas and rate limits.
   * Quotas will be reset on the next quota renew date that depends on application creation date
   * and the organization billing plan period.
   */
  deleteApplicationQuotasBySysadmin: { request: string; response: void };

  /**
   * Creates an AWS-billed organization associated with the purchase.
   */
  activateAwsPurchase: { request: ActivateAwsPurchaseRequest; response: OrganizationId };

  /**
   * Activates a GCP marketplace purchase: creates an account, approves the entitlement.
   * Creates a related GCP-billed organization associated with the purchase (entitlement).
   */
  activateGcpPurchase: { request: ActivateGcpPurchaseRequest; response: OrganizationId };
}

/**
 * Calls {@link Squid.executeFunction} in a type safe way: limits the function names,
 * input args and output args to ones described in ConsoleBackendExecutableMap.
 */
export async function callBackendExecutable<FunctionName extends keyof ConsoleBackendExecutableMap>(
  squid: Squid,
  executableName: FunctionName,
  request: ConsoleBackendExecutableMap[FunctionName]['request'],
  file?: File,
): Promise<ConsoleBackendExecutableMap[FunctionName]['response']> {
  const startTime = Date.now();
  try {
    if (file) {
      return await squid.executeFunction(executableName, file, request);
    }
    return await squid.executeFunction(executableName, request);
  } finally {
    squid.observability.reportMetric({
      name: 'web_callBackendExecutable_time',
      value: Date.now() - startTime,
      tags: { executableName },
    });
  }
}

export interface CreateInvitationRequest {
  organizationId: OrganizationId;
  email: string;
  role: OrganizationRole;
}

export interface ResendInvitationRequest {
  organizationId: OrganizationId;
  invitationKey: InvitationKey;
}

export interface QuotaState<QuotaType extends OrganizationQuotaName | ApplicationQuotaName> {
  id: QuotaType;

  /** Available quota value in Squid. */
  available: number;

  /**
   * Max value according to the current billing plan.
   * If `max` is not present - it is unlimited.
   */
  max: number;
}

export interface OrganizationQuotasResponse {
  organizationId: OrganizationId;
  /** Current organization quotas and their max value. */
  quotas: Array<QuotaState<OrganizationQuotaName>>;
}

export interface ApplicationQuotasRequest {
  organizationId: OrganizationId;
  applicationId: AppId;
}

export interface ApplicationQuotasResponse {
  applicationId: AppId;
  /** Current organization quotas and their max value. */
  quotas: Array<QuotaState<ApplicationQuotaName>>;
}

export interface OrganizationM3BillsResponse {
  organizationId: OrganizationId;
  bills: Array<M3Bill>;
}

export interface OrganizationCpBillsRequest {
  organizationId: string;
  /** List of bills ids to return. If not defined or empty - all organization bills are returned. */
  billIds?: Array<string>;
}

export interface OrganizationCpBillsResponse {
  organizationId: OrganizationId;
  bills: Array<CpBill>;
}

export interface ConsoleCreateApplicationRequest {
  organizationId: OrganizationId;
  name: string;
  cloudId: SquidCloudId;
  region: string;
  shard?: string;
}

export interface ConsoleCreateApplicationResponse {
  appId: string;
}

export interface ConsoleUpsertIntegrationRequest {
  organizationId: OrganizationId;
  applicationId: AppId;
  upsert: UpsertIntegrationRequest;
}

export interface TestDataConnectionRequest {
  applicationId: AppId;
  config: BaseIntegrationConfig;
}

export interface TestGraphQLConnectionRequest {
  applicationId: AppId;
  config: GraphQLIntegrationConfig;
}

export interface ConsoleDeleteIntegrationRequest {
  organizationId: OrganizationId;
  applicationId: AppId;
  integrationId: IntegrationId;
}

export interface ConsoleUpsertIntegrationSchemaRequest {
  organizationId: OrganizationId;
  applicationId: AppId;
  integrationId: IntegrationId;
  upsert: IntegrationSchema;
}

export interface ConsoleDeleteBuiltInCollectionRequest {
  organizationId: OrganizationId;
  applicationId: AppId;
  integrationId: IntegrationId;
  collectionName: string;
}

export interface GetSchemaRequest {
  appId: AppId;
  integrationId: IntegrationId;
}

/**
 * Updates a single quota state for an organization or an application.
 * When update for an application both dev and prod apps are updated.
 */
export interface UpdateQuotaBySysadminRequest {
  /** Organization of application id. Defined by the type of the quota. */
  entityId: AppId | OrganizationId;
  quota: QuotaName;
  /**
   * Maximum allowed value of the quota. When present both Plan and Redis are updated:
   * the `maxPlanValue` is saved to the organization billing plan and (`maxPlanValue` - currentUsage) is
   * stored to the Redis.
   */
  maxPlanValue?: number;

  /** Available value for the quota. When is present only Redis is updated. */
  availableValue?: number;
}

/** A request to change organization billing plan. */
export interface ChangeBillingPlanBySysadminRequest {
  billingPlanTemplateId: BillingPlanTemplateId;
  organizationId: OrganizationId;
}

export const CONTEST_TYPES = ['build-with-squid'] as const;
export type ContestType = (typeof CONTEST_TYPES)[number];

export interface ContestSubmission {
  type: ContestType;
  data: {
    name: string;
    email: string;
    [key: string]: unknown;
  };
}
