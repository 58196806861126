import { assertTruthy, formatError, ValueAssertion } from 'assertic';

/** List of all organization quotas supported by the system. */
export const ORGANIZATION_QUOTA_NAMES = [
  /** Maximum number of applications per organization. */
  'maxNumberOfApplications',
] as const;

/** List of all application quotas supported by the system. */
export const APPLICATION_QUOTA_NAMES = [
  /**
   * The total number of squid actions allowed per billing period. This includes:
   * - Queries
   * - Mutations
   * - Realtime updates
   * - Triggers
   * - Scheduled actions
   * - Executing backend functions
   * - Executing webhooks
   * - Executing named queries
   * - Executing API requests
   * - Executing graphql requests
   * - Chat with an api key
   */
  'numberOfSquidActions',

  /** If the user provided their own API key, we will not count it and the chat will be a regular Squid action. */
  'numberOfGpt4Tokens',
  'numberOfGpt35Tokens',
  'numberOfClaude2Tokens',
  'numberOfGeminiProTokens',

  'numberOfGpt4Chats',
  'numberOfGpt35Chats',
  'numberOfClaude2Chats',
  'numberOfGeminiProChats',

  /** The total number of AI chatbot messages allowed per billing period. This includes: */
  'numberOfAiImagesGenerated',
  'numberOfAiAudioTranscriptions',

  /** Maximum number of integrations allowed for all applications in the organization. */
  'maxNumberOfIntegrations',

  /** The total number of concurrent web socket connections allowed. */
  'maxNumberOfConcurrentConnections',

  /** Secrets are stored in Vault and are more expensive to manage from the regular Squid Actions. */
  'numberOfSecretMutations',
  'numberOfSecretQueries',
  'numberOfConcurrentLocks',

  /** Locks are not regular Squid Actions and are more expensive to manage from the regular Squid Actions. */
  'numberOfLocks',

  /** AI Chatbot context size limit. */
  'maxAiContextBytes',

  /** Message Queue total bytes produced. */
  'maxMessageQueueBytes',

  /** Count of user metrics reported per period. */
  'numberOfMetrics',

  /** Number of AI audio createSpeech calls. */
  'numberOfAiAudioCreateSpeechCalls',
] as const;

export const QUOTA_NAMES = [...ORGANIZATION_QUOTA_NAMES, ...APPLICATION_QUOTA_NAMES] as const;
export type ApplicationQuotaName = (typeof APPLICATION_QUOTA_NAMES)[number];
export type OrganizationQuotaName = (typeof ORGANIZATION_QUOTA_NAMES)[number];
export type QuotaName = (typeof QUOTA_NAMES)[number];

export function isApplicationQuotaName(name: unknown): name is ApplicationQuotaName {
  return APPLICATION_QUOTA_NAMES.includes(name as ApplicationQuotaName);
}

export function isOrganizationQuotaName(name: unknown): name is OrganizationQuotaName {
  return ORGANIZATION_QUOTA_NAMES.includes(name as OrganizationQuotaName);
}

/** List of all rate limit types in the system. Per application. */
export const RATE_LIMITS_NAMES = [
  'aiChatPerSecond',
  /** Count of metric query calls per second. */
  'metricQueriesPerSecond',
  /** Count of metric reporting calls per second (a single call can include a batch of metrics). */
  'metricReportsPerSecond',
  'secretMutationsPerSecond',
  'secretQueriesPerSecond',
  'squidActionsPerSecond',
] as const;

export type RateLimitName = (typeof RATE_LIMITS_NAMES)[number];

/*** Asserts that `value` is a valid QuotaName string.  */
export const assertQuotaName: ValueAssertion<QuotaName> = (
  value: unknown,
  context = undefined,
): asserts value is QuotaName => {
  assertTruthy(QUOTA_NAMES.includes(value as QuotaName), () => formatError(context, `Not a valid quota name`, value));
};
