<ng-container *ngVar="bundleDataTableData$ | async as bundleDataTableData">
  @if (bundleDataTableData === null) {
    <loading-indicator></loading-indicator>
  } @else if (bundleDataTableData) {
    <div class="table_container">
      <bundle-data-table
        [bundleDataTableData]="bundleDataTableData"
        stateKey="triggers"
        (selectedPeriodTypeChange)="periodType$.next($event)"
      />
    </div>
  } @else {
    <div class="functions_doc_wrapper">
      <triggers-tutorial></triggers-tutorial>
    </div>
  }
</ng-container>
