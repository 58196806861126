<div class="integration_page" *ngIf="integrationObs | async as integration">
  <div class="container">
    <ng-container
      *ngTemplateOutlet="indexToHeaderTemplateMap[selectedIndex] || null"
    />
  </div>
  <mat-tab-group
    #tabGroup
    (selectedTabChange)="changeUrl(tabGroup.selectedIndex)"
    [selectedIndex]="selectedIndex"
    animationDuration="0ms"
    color="primary"
    disableRipple="true"
    mat-align-tabs="start"
    mat-stretch-tabs="false"
  >
    @for (tabId of tabs; track tabId) {
      <mat-tab *ngIf="tabId === 'edit'">
        <ng-template mat-tab-label>
          <div data-testid="integration-tab-label-details">Details</div>
        </ng-template>
        <app-integration-form
          class="tab"
          (headerTemplateChange)="handleHeaderTemplateChange(tabId, $event)"
        />
      </mat-tab>
      <mat-tab *ngIf="tabId === 'schema'" class="tab">
        <ng-template mat-tab-label>
          <div data-testid="integration-tab-label-schema">Schema</div>
        </ng-template>
        <app-schema
          class="tab"
          (headerTemplateChange)="handleHeaderTemplateChange(tabId, $event)"
        />
      </mat-tab>
      <mat-tab *ngIf="tabId === 'profiles'" class="tab">
        <ng-template mat-tab-label>
          <div data-testid="integration-tab-label-profiles">Agents</div>
        </ng-template>
        <app-schema
          class="tab"
          (headerTemplateChange)="handleHeaderTemplateChange(tabId, $event)"
        />
      </mat-tab>
      <mat-tab *ngIf="tabId === 'db-browser'" class="tab">
        <ng-template mat-tab-label>
          <div data-testid="integration-tab-label-browse">Browse</div>
        </ng-template>
        <app-db-browser
          class="tab"
          (headerTemplateChange)="handleHeaderTemplateChange(tabId, $event)"
        />
      </mat-tab>
      <mat-tab *ngIf="tabId === 'how-to-use'" class="tab">
        <ng-template mat-tab-label>
          <how-to-use />
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
</div>
