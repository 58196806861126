@if (application$ | async; as application) {
  <ng-container *ngVar="bundleDataTableData$ | async as bundleDataTableData">
    @if (bundleDataTableData === null) {
      <loading-indicator></loading-indicator>
    } @else if (bundleDataTableData) {
      <div class="table_container">
        <bundle-data-table
          [bundleDataTableData]="bundleDataTableData"
          stateKey="openapi"
          (selectedPeriodTypeChange)="periodType$.next($event)"
        />
        <div class="spec_link">
          The generated OpenAPI specification is available
          <a [href]="getOpenApiBaseUrl(application)+'/spec.json'" target="_blank">here</a>
        </div>
      </div>
    } @else {
      <div class="functions_doc_wrapper">
        <openapi-tutorial />
      </div>
    }
  </ng-container>
}
