import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  filter,
  finalize,
  from,
  map,
  MonoTypeOperatorFunction,
  Observable,
  shareReplay,
  switchMap,
  timer,
} from 'rxjs';
import { MetricsService } from '../metric/metrics.service';
import { BackendMetricPeriodType, GetBackendMetricsResponse } from '@squidcloud/console-common/types/metrics.types';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { MILLIS_PER_SECOND } from '@squidcloud/internal-common/types/time-units';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  backendMetadataAvailableSubject = new BehaviorSubject<boolean>(false);
  private readonly metricsObservablesMap = new Map<BackendMetricPeriodType, Observable<GetBackendMetricsResponse>>();

  constructor(
    private readonly metricsService: MetricsService,
    private readonly applicationService: ApplicationService,
  ) {}

  reportBackendMetadataAvailable(isAvailable: boolean): void {
    this.backendMetadataAvailableSubject.next(isAvailable);
  }

  observeBackendMetadataAvailable(): Observable<boolean> {
    return this.backendMetadataAvailableSubject.asObservable();
  }

  getMetricsObs(
    periodType: BackendMetricPeriodType,
    takeUntilDestroyedOperator: MonoTypeOperatorFunction<unknown>,
  ): Observable<GetBackendMetricsResponse> {
    let obs = this.metricsObservablesMap.get(periodType);
    if (!obs) {
      obs = timer(0, 10 * MILLIS_PER_SECOND).pipe(
        switchMap(() => this.applicationService.currentApplication$),
        filter(Boolean),
        map(app => app.appId),
        switchMap(appId =>
          from(this.metricsService.getMetrics(appId, periodType)).pipe(
            catchError(error => {
              console.error(`Failed to fetch metrics for appId: ${appId}`, error);
              return EMPTY;
            }),
          ),
        ),
        takeUntilDestroyedOperator as MonoTypeOperatorFunction<GetBackendMetricsResponse>,
        shareReplay(1),
        finalize(() => {
          this.metricsObservablesMap.delete(periodType);
        }),
      );
      this.metricsObservablesMap.set(periodType, obs);
    }
    return obs;
  }

  observeMetricByPeriodType(
    periodType$: Observable<BackendMetricPeriodType>,
    takeUntilDestroyed: MonoTypeOperatorFunction<unknown>,
  ): Observable<GetBackendMetricsResponse> {
    return periodType$.pipe(switchMap(periodType => this.getMetricsObs(periodType, takeUntilDestroyed)));
  }
}
