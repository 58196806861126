import { ConfluenceIntegration } from './confluence';
import { BaseIntegration } from './base';
import { IntegrationType } from '../public-types/integration.public-types';
import { LinearIntegration } from './linear';
import { IntegrationApiSchema } from '../types/integrations/api.types';
import { IntegrationConfigTypes, IntegrationTypeWithConfig } from '../types/integrations/schemas';

export const AI_AGENTS_ID = 'ai_agents';

export type SchemaOverrides = DeepPartial<IntegrationApiSchema>;

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export const INTEGRATION_TRANSFORM_MAP: {
  [T in IntegrationTypeWithConfig]?: new () => BaseIntegration<IntegrationConfigTypes[T]>;
} = {
  [IntegrationType.confluence]: ConfluenceIntegration,
  [IntegrationType.linear]: LinearIntegration,
};

export function getIntegrationTransformer<T extends IntegrationTypeWithConfig>(
  type: T,
): BaseIntegration<IntegrationConfigTypes[T]> {
  const Transformer = INTEGRATION_TRANSFORM_MAP[type];
  return Transformer ? new Transformer() : new BaseIntegration<IntegrationConfigTypes[T]>();
}
