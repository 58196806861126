<ng-container *ngVar="bundleDataTableDataObs | async as bundleDataTableData">
  @if (bundleDataTableData === null) {
    <loading-indicator></loading-indicator>
  } @else if (bundleDataTableData) {
    <div class="table_container" data-testid="webhooks-table">
      <bundle-data-table
        [bundleDataTableData]="bundleDataTableData"
        stateKey="webhooks"
        (selectedPeriodTypeChange)="periodType$.next($event)"
      />
    </div>
  } @else {
    <div class="functions_doc_wrapper">
      <webhooks-tutorial></webhooks-tutorial>
    </div>
  }
</ng-container>
