<ng-container *ngVar="selectedCollectionSubject | async as selectedCollection">
  <ng-container *ngIf="integrationObs | async as integration">
    <ng-container *ngVar="schemaObs | async as schema">
      <div *ngIf="modificationsObs | async as modifications" class="schema">
        <portal class="flex_shrink_0" [emitter]="headerTemplateChange" [teleport]="!isNewSchema">
          <integration-header [integrationType]="integration.type"
                              [title]="isNewSchema ? 'Define schema' : integration.id"
                              [step]="isNewSchema ? [2, 2] : [0, 0]"
                              [withTabs]="!isNewSchema">
            <ng-container buttons>
              <button [disabled]="!schemaInitialized"
                      (click)="toggleAiQueryTest()"
                      class="toggle_ai_query_test_button secondary_button large_button"
                      mat-flat-button
                      type="submit">
                <mat-icon class="ai_icon" svgIcon="ai_icon" />
                Query with AI
              </button>
              <button [disabled]="!schemaInitialized"
                      (click)="showRediscoverSchemaDialog(false)"
                      class="discover_schema secondary_button large_button"
                      mat-flat-button
                      type="submit">
                Rediscover Schema
              </button>
              <button [disabled]="!schemaInitialized"
                      (click)="showRediscoverSchemaDialog(true)"
                      class="secondary_button large_button"
                      mat-flat-button
                      type="submit">
                <mat-icon class="ai_icon" svgIcon="ai_icon" />
                Generate Descriptions with AI
              </button>
              <div class="inline_block" *ngVar="('ADMIN' | minRole) as isAdmin"
                   [matTooltip]="!isAdmin ? 'You need to be an admin to edit the schema' : ''">
                <button (click)="saveSchema(integration.type)"
                        [disabled]="disableSaveSchemaButton(isAdmin, modifications.isModified(), integration.type)"
                        class="save_schema primary_button large_button"
                        mat-flat-button
                        type="submit">
                  {{ 'Save Schema' }}
                </button>
              </div>
            </ng-container>
          </integration-header>
        </portal>

        <ng-container *ngIf="schema">
          <div class="page_content">
            <div class="left">
              <div class="left_tables">
                <div class="left_table">
                  <schema-table-header
                    class="mb_16"
                    title="Collections"
                    (add)="showAddCollectionDialog(integration.type)"
                  />
                  <div class="left_rows">
                    @for (collectionName of getSortedKeys(schema.collections); track collectionName) {
                      <schema-select-row
                        [title]="collectionName"
                        icon="browser_collections_icon"
                        [isModified]="modifications.isPathModified(collectionName)"
                        [selected]="collectionName === selectedCollection"
                        (activate)="selectCollection(collectionName)"
                      />
                    }
                  </div>
                </div>
              </div>
            </div>

            <div class="right">
              <ng-container *ngIf="selectedCollection; else nothingToSee">
                <div class="right_table mb_24">
                  <schema-table-header
                    [ngClass]="{'mb_24': selectedCollectionFields.length}"
                    [title]="selectedCollection"
                    icon="browser_collections_icon"
                    [isModified]="modifications.isPathModified(selectedCollection)"
                    [hideGenerateTypeScriptInterface]="selectedCollectionFields.length === 0"
                    (edit)="showEditCollectionDialog(integration.type)"
                    (generateTypeScriptInterface)="showTypeScriptInterfaceDialog(selectedCollection)"
                    (generateAiDescription)="showGenerateAiDescriptionsDialog(selectedCollection)"
                    (add)="showAddFieldDialog(selectedCollection, integration.type)"
                  />
                  <div *ngIf="selectedCollectionFields.length" class="collection_table common_table">
                    <div class="row header_row">
                      <div class="column">Name</div>
                      <div class="column">Type</div>
                      <div class="column">Primary key</div>
                      <div class="column">Required</div>
                    </div>
                    @for (row of selectedCollectionFields; track row.name) {
                      <div [ngClass]="{hidden_row: row.hidden, row: true}">
                        <div class="column icon_column">
                          <mat-icon *ngIf="row.hidden" class="reveal_icon" svgIcon="reveal_icon"
                                    matTooltip="Hidden fields exist in the database but are not used by Squid or exposed to users." />
                          <span class="truncate"> {{ row.name }} </span><span *ngIf="row.modified"
                                                                              class="modified_dot"></span></div>
                        <div class="column">{{ row.type }}</div>
                        <div class="column">{{ row.primaryKey ? 'Yes' : 'No' }}</div>
                        <div class="column">{{ row.required ? 'Yes' : 'No' }}</div>
                        <div class="column action_column">
                          <schema-field-menu
                            [element]="row.name"
                            [hidden]="!!row.hidden"
                            [disableHide]="row.primaryKey"
                            (edit)="showEditFieldDialog(selectedCollection, $event, integration.type)"
                            (delete)="showDeleteFieldDialog($event)"
                            (duplicate)="duplicateField($event)"
                            (hide)="toggleHiddenField(selectedCollection, $event)"
                          />
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <mini-backend-functions
                  class="mb_24"
                  [title]="selectedCollection"
                  [databaseInput]="{integrationId,collectionName: selectedCollection}" />
              </ng-container>
              <ng-template #nothingToSee>
                <nothing-to-see />
              </ng-template>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
