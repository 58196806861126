import { DebugLogger, getGlobal } from './utils/global.utils';

function noop(): void {
  return;
}

interface Options {
  maxAttempts?: number;
  protocols?: string[];
  onmessage?: (event: any) => void;
  onopen?: (event: any) => void;
  onclose?: (event: any) => void;
  onerror?: (event: any) => void;
  onreconnect?: (event: any) => void;
  onmaximum?: (event: any) => void;
  timeoutMillis?: number;
}

export interface WebSocketWrapper {
  open: () => void;
  reconnect: (e: any) => void;
  json: (x: any) => void;
  send: (x: string) => void;
  close: (x?: number, y?: string) => void;
  connected: boolean;
  /**
   * Websocket is explicitly closed by calling socket.close().
   * Used to ignore errors after socket.closed() is called.
   */
  closeCalled: boolean;
}

const isNode = typeof process !== 'undefined' && !!process.versions?.node;
const WebSocketClass = isNode ? require('ws') : getGlobal()['WebSocket'];

export function createWebSocketWrapper(url: string, opts: Options = {}): WebSocketWrapper {
  let ws: any;
  let num = 0;
  let timer: any = 1;
  const $: WebSocketWrapper = {
    connected: false,
    closeCalled: false,
    open() {
      const wsConstructor = WebSocketClass?.['WebSocket'] ?? WebSocketClass;
      ws = new wsConstructor(url, opts.protocols || []);

      ws.onmessage = opts.onmessage
        ? (e: MessageEvent): void => {
            if (!e.data || !opts.onmessage) {
              console.log('No data received from websockets, please contact support@squid.cloud with this message.');
              return;
            }
            opts.onmessage(e);
          }
        : noop;

      ws.onopen = function (e: Event): void {
        $.connected = true;
        (opts.onopen || noop)(e);
        num = 0;
      };

      ws.onclose = function (e: any): void {
        $.connected = false;
        if (e.code !== 4999 && e.code !== 4001) {
          DebugLogger.debug('WebSocket closed. Reconnecting. Close code: ', e.code);
          (opts.onclose || noop)(e);
          $.reconnect(e);
          return;
        }
        (opts.onclose || noop)(e);
      };

      ws.onerror = function (e: Event): void {
        $.connected = false;
        if (e && 'ECONNREFUSED' === (e as any).code) {
          $.reconnect(e);
        } else if (!$.closeCalled) {
          (opts.onerror || noop)(e);
        }
      };
    },

    reconnect(e: any) {
      const maxAttempts = opts.maxAttempts !== undefined ? opts.maxAttempts : Infinity;
      if (timer && num++ < maxAttempts) {
        timer = setTimeout(function (): void {
          (opts.onreconnect || noop)(e);
          DebugLogger.debug('WebSocket trying to reconnect...');
          $.open();
        }, opts.timeoutMillis || 1000);
      } else {
        (opts.onmaximum || noop)(e);
      }
    },

    json(x: any) {
      ws.send(JSON.stringify(x));
    },

    send(x: string | ArrayBufferLike | Blob | ArrayBufferView) {
      ws.send(x);
    },

    close(code = 4999, message?: string) {
      $.closeCalled = true;
      try {
        $.connected = false;
        clearTimeout(timer);
        timer = undefined;
        ws.close(code, message);
      } catch (_) {}
    },
  };

  $.open();

  return $;
}
