import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';
import { SquidAngularValidators } from '@squidcloud/console-web/app/utils/angular-utils';

export type FormElementType = 'input' | 'select' | 'boolean' | 'secret' | 'textarea' | 'file';
export type FormElement = FormInput | FormSelect | FormBoolean | FormSecret | FormTextArea | FileUpload;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type onChangeFn = (formElement: FormElement, data: any) => FormElement;

interface BaseFormElement<ValueType = unknown> {
  type: FormElementType;
  nameInForm: string;
  required: boolean;
  label: string;
  hint?: string;
  description?: string;
  placeholder?: string;
  defaultValue?: ValueType;
  extraValidators?: Array<ValidatorFn>;
  onChange?: onChangeFn;
  hidden?: boolean;
  readonly?: boolean;
  grouped?: boolean;
  showErrorInTooltip?: boolean;
  /** Works only for 'input', 'textarea', 'select' and 'secret' field types. */
  floatLabel?: FloatLabelType;
}

export interface FormInput extends BaseFormElement {
  type: 'input';
  inputType?: 'text' | 'password' | 'email' | 'number';
}

export type TextAreaAttributes = {
  autosize?: boolean;
  minRows?: number;
  maxRows?: number;
  minHeight?: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FormTextArea<FloatingActionDataType = any> extends BaseFormElement {
  type: 'textarea';
  attributes: TextAreaAttributes;
  floatingAction?: FormTextAreaFloatingAction<FloatingActionDataType>;
}

export interface FormTextAreaFloatingAction<T = unknown> {
  buttonIcon: string;
  buttonText: string;
  buttonTitle?: string;
  isActionInProgress: boolean;
  data: T;
  clickCallback: (action: FormTextAreaFloatingAction<T>, form: FormGroup) => Promise<void> | void;
}

// If the function response is a string, this is considered an error message.
export type FormCallbackResponse = Promise<string | undefined | void> | string | undefined | void;

export interface FileUpload extends BaseFormElement {
  type: 'file';
  fileTypes?: string;
}

export interface FormSelectOption<ValueType = unknown> {
  name: string;
  value: ValueType;
}

export interface FormSelect extends BaseFormElement {
  type: 'select';
  options: Array<FormSelectOption>;
}

export interface FormBoolean extends BaseFormElement {
  type: 'boolean';
}

export interface FormSecret extends BaseFormElement {
  type: 'secret';
}

export const FormUtils = {
  getInputType: (formElement: FormElement): string => {
    return formElement.type === 'input' && formElement.inputType ? formElement.inputType : 'text';
  },
  getSelectOptions: (formElement: FormElement): Array<FormSelectOption> => {
    return formElement.type === 'select' ? formElement.options : [];
  },
  getTextAreaAttributes: (formElement: FormElement): TextAreaAttributes => {
    return formElement.type === 'textarea' ? formElement.attributes : {};
  },
  getFileTypes: (formElement: FormElement): string | undefined => {
    return formElement.type === 'file' ? formElement.fileTypes : undefined;
  },
  getDefaultValue: (formElement: FormElement): unknown => {
    let defaultValue = formElement.defaultValue;
    if (!defaultValue) {
      defaultValue = typeof defaultValue === 'boolean' || formElement.type === 'boolean' ? false : '';
    }
    return defaultValue;
  },
  getValidators: (formElement: FormElement): Array<ValidatorFn> => {
    if (formElement.hidden) return [];

    const validators: Array<ValidatorFn> = [...(formElement.extraValidators ?? [])];
    if (formElement.type === 'input' && formElement.inputType === 'email') {
      validators.push(SquidAngularValidators.email);
    }
    if (formElement.required) {
      validators.push(Validators.required);
    }
    return validators;
  },
};

export const NamePattern =
  /^(?=.*\S)[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
